import { motion } from "framer-motion";
import UserAvatar from "../../../assets/icons/userAvatar.png";
import { Spinner } from "react-activity";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "./profilePage.module.css";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { CallPostApiServicesWithTkn } from "../../../utils/webServices/apiCalls";
import { AuthContext } from "../../../components/store/context/authContextProvider";

function ProfilePage() {
  const { userName, userEmail, courseType, mblNo, paid, token, logout } =
    useContext(AuthContext);
  const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordErrMsg, setNewPasswordErrMsg] = useState(null);
  const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(false);
  const [oldPasswordMsg, setOldPasswordMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  const [oldPasswordCheckLoader, setOldPasswordCheckLoader] = useState(false);

  function resetViewBtnHandler() {
    setIsResetPasswordVisible(true);
  }

  useEffect(() => {
    if (!oldPassword) {
      if (oldPasswordMsg) {
        setOldPasswordMsg(null);
      }
      return;
    }
    const timeoutId = setTimeout(() => {
      setOldPasswordCheckLoader(true);
      CallPostApiServicesWithTkn(
        `/user/check-user-password`,
        {
          email: userEmail,
          enteredPassword: oldPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        (response) => {
          if (response.status === 200) {
            setOldPasswordCheckLoader(false);
            setIsOldPasswordCorrect(true);
            setOldPasswordMsg(response.data?.message);
          }
        },
        (err) => {
          setIsOldPasswordCorrect(false);
          setOldPasswordCheckLoader(false);
          setOldPasswordMsg(err.response?.data?.message);
          console.log(err.response?.data?.message);
        }
      );
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [oldPassword]);

  function oldPasswordHandler(oldPw) {
    setOldPassword(oldPw);
  }

  function newPasswordHandler(text) {
    setNewPassword(text);
  }

  function resetCancelHandler() {
    setIsResetPasswordVisible(false);
    setOldPassword(null);
    setIsOldPasswordCorrect(false);
    setOldPasswordMsg(null);
    setNewPassword(null);
    setNewPasswordErrMsg(null);
  }

  function resetHandler() {
    if (isOldPasswordCorrect && newPassword !== null && oldPassword !== null) {
      console.log("here 2");
      setIsLoading(true);
      CallPostApiServicesWithTkn(
        `/user/reset-password-profile`,
        {
          email: userEmail,
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        (response) => {
          if (response.status === 200) {
            toast.success("password updated successfully.", {
              autoClose: 2000,
            });
            resetCancelHandler();
            setTimeout(() => {
              toast.success("Logging you off, please login again. ", {
                autoClose: 1500,
              });
            }, 1500);

            setTimeout(() => {
              setIsLoading(false);
              logout();
            }, 4000);
          }
        },
        (err) => {
          console.log("err updating new password");
          setIsLoading(false);
          toast.error("Server error, please try again.");
        }
      );
    }
  }

  function resetBtnHandler() {
    if (newPassword.length === 0) {
      setNewPasswordErrMsg(null);
    } else if (
      newPassword.length < 6 ||
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9.!@#$%^&*]+$/.test(newPassword)
    ) {
      setNewPasswordErrMsg(
        "Password - at least 6 characters - at least one digit - one special character."
      );
    } else {
      console.log("hereeeeeeeeeeee");
      setNewPasswordErrMsg(null);

      resetHandler();
    }
  }

  return (
    <div className={styles.profile_container_main}>
      <div className={styles.profile_container_main_sub}>
        <div className={styles.profile_container_main_sub_img}>
          <motion.div
            className={styles.profile_circle}
            whileHover={{
              scale: 1.05,
              boxShadow: "0 0 8px 0.5px #313538",
            }}
          >
            <motion.img
              src={UserAvatar}
              alt="logo"
              width={"90%"}
              height={"90%"}
              whileHover={{
                scale: 1.05,
              }}
            />
          </motion.div>
        </div>
        <div className={styles.profile_container_main_sub_details}>
          <div className={styles.input_cont}>
            <div className={styles.label_input}>
              <label className={styles.label} htmlFor="name">
                Name
              </label>
              <input
                className={styles.input_field}
                value={userName || null}
                type="text"
                id="name"
                name="name"
                required
                disabled
              />
            </div>
            <div className={styles.label_input}>
              <label className={styles.label} htmlFor="email">
                Email
              </label>
              <input
                className={styles.input_field}
                value={userEmail || null}
                type="email"
                id="email"
                name="email"
                required
                disabled
              />
            </div>
          </div>

          <div className={styles.input_cont}>
            <div className={styles.label_input}>
              <label className={styles.label} htmlFor="mobile-no">
                Mobile no
              </label>
              <input
                className={styles.input_field}
                value={mblNo || null}
                type="text"
                id="mobile-no"
                name="mobile-no"
                required
                disabled
              />
            </div>
            <div className={styles.label_input}>
              <label className={styles.label} htmlFor="course-type">
                Plan type
              </label>
              <input
                className={styles.input_field}
                value={paid ? courseType : "none"}
                type="text"
                id="course-type"
                name="course-type"
                required
                disabled
              />
            </div>
          </div>

          <div className={styles.input_reset_cont}>
            {isResetPasswordVisible ? (
              <>
                <div className={styles.input_reset_cont_sub}>
                  <div className={styles.label_input}>
                    <label className={styles.label} htmlFor="current-password">
                      Current password
                    </label>
                    <input
                      className={styles.input_field}
                      type="password"
                      id="current-password"
                      name="current-password"
                      required
                      onChange={(e) => oldPasswordHandler(e.target.value)}
                    />
                    {oldPasswordMsg && (
                      <div className={styles.pw_text_indicator}>
                        {oldPasswordCheckLoader && (
                          <Spinner size={12} color="#0c969a" />
                        )}
                        <span
                          style={{
                            color: isOldPasswordCorrect ? "#00ff0a" : "red",
                          }}
                        >
                          {oldPasswordMsg}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={styles.label_input}>
                    <label className={styles.label} htmlFor="new-password">
                      New password
                    </label>
                    <input
                      className={styles.input_field}
                      style={{ marginBottom: "1rem" }}
                      type="password"
                      id="new-password"
                      name="new-password"
                      required
                      onChange={(e) => newPasswordHandler(e.target.value)}
                      disabled={!isOldPasswordCorrect}
                    />
                    <span
                      style={{
                        color: newPasswordErrMsg ? "red" : "",
                        fontSize: "0.9rem",
                      }}
                    >
                      {newPasswordErrMsg}
                    </span>
                  </div>
                </div>

                <div
                  className={styles.input_reset_cont_sub_btns}
                  style={{ justifyContent: "flex-start" }}
                >
                  <ButtonComponent
                    text={"Cancel"}
                    style={styles.reset_password_btn}
                    handler={resetCancelHandler}
                  />
                  <ButtonComponent
                    text={"Reset"}
                    style={styles.reset_password_btn}
                    disabled={
                      !isOldPasswordCorrect ||
                      newPassword === null ||
                      oldPassword === null ||
                      isLoading
                    }
                    indicator={isLoading}
                    handler={resetBtnHandler}
                  />
                </div>
              </>
            ) : (
              <ButtonComponent
                text={"Reset password ?"}
                handler={resetViewBtnHandler}
                style={styles.reset_password_btn}
              />
            )}
          </div>

          <div
            className={styles.logout_btn_cont}
            style={{ marginTop: isResetPasswordVisible ? "1.5%" : "13%" }}
          >
            <ButtonComponent
              text={"Logout"}
              style={styles.logout_btn}
              handler={() => {
                setLogoutLoader(true);
                setTimeout(() => {
                  setLogoutLoader(false);
                  logout();
                }, 2000);
              }}
              indicator={logoutLoader}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
