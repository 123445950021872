import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./carouselWhatWeDo.module.css";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../buttonComponent/buttonComponent";

const SliderCarouselWhatWeDo = ({ closeModal }) => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.main_carousel}>
      <Carousel controls={false}>
        <Carousel.Item>
          <div className={styles.carousel_item}>
            {windowWidth > 924 && (
              <div className={styles.cross_btn}>
                <IoIosClose
                  size={30}
                  color="#9ba1a6"
                  style={{ cursor: "pointer" }}
                  onClick={() => closeModal()}
                />
              </div>
            )}
            <div className={styles.carousel_item_sub}>
              <div
                className={styles.carousel_item_left}
                style={{
                  width: "90%",
                  textAlign: "center",
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <h2>
                  Hmm... It's better to show what we did instead of showing what
                  we do.
                </h2>

                {windowWidth <= 924 ? (
                  <>
                    <h2> Agree ?</h2>
                    <h2>If yes, swipe left</h2>
                  </>
                ) : (
                  <h2> Agree ?, If yes, swipe left</h2>
                )}

                {/* 
                <IoIosArrowDroprightCircle
                  size={60}
                  color="#0c969a"
                  onClick={closeModal}
                /> */}
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className={styles.carousel_item}>
            {windowWidth > 924 && (
              <div className={styles.cross_btn}>
                <IoIosClose
                  size={30}
                  color="#9ba1a6"
                  onClick={closeModal}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}
            <div className={styles.carousel_item_sub}>
              <div className={styles.carousel_item_left}>
                <h2>
                  We employed hybrid analysis strategy for analyzing stocks.
                </h2>

                <p>
                  This approach integrates insights from both fundamental
                  factors such as financial statements, earnings, and market
                  position, as well as technical factors like price trends,
                  volume patterns, and chart formations.
                </p>

                <div className={styles.numbers_cont}>
                  <div className={styles.numbers_sub}>
                    <h3 style={{ color: "#0a427d" }}>72+%</h3>
                    <span>Reward percentage</span>
                  </div>

                  <div className={styles.numbers_sub}>
                    <h3 style={{ color: "#ff802b" }}>14:40</h3>
                    <span>5 months reward</span>
                  </div>

                  <div className={styles.numbers_sub}>
                    <h3 style={{ color: "#3cb179" }}>18:48</h3>
                    <span>Overall reward</span>
                  </div>
                </div>
              </div>
              <div className={styles.carousel_item_right}>
                <div className={styles.carousel_item_right_sub}>
                  <p>Check out our performance</p>
                  <ButtonComponent
                    text={"View"}
                    handler={() => {
                      navigate("/dashboard");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className={styles.carousel_item}>
            {windowWidth > 924 && (
              <div className={styles.cross_btn}>
                <IoIosClose
                  size={30}
                  color="#9ba1a6"
                  onClick={closeModal}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}
            <div className={styles.carousel_item_sub}>
              <div className={styles.carousel_item_left}>
                <h2>Charts analyzed using hybrid model were shared.</h2>

                <p>
                  We've meticulously analyzed numerous charts using a hybrid
                  model, greatly improving our understanding of the data
                  showcased in our performance. This thorough examination
                  enhances comprehension and provides valuable insights.
                </p>

                <div className={styles.numbers_cont}>
                  <div className={styles.numbers_sub}>
                    <h3 style={{ color: "#0a427d" }}>200+</h3>
                    <span>Analysis shared</span>
                  </div>

                  <div className={styles.numbers_sub}>
                    <h3 style={{ color: "#ff802b" }}>80%</h3>
                    <span>Target hit</span>
                  </div>

                  <div className={styles.numbers_sub}>
                    <h3 style={{ color: "#3cb179" }}>20%</h3>
                    <span>Stoploss hit</span>
                  </div>
                </div>
              </div>
              <div className={styles.carousel_item_right}>
                <div className={styles.carousel_item_right_sub}>
                  <p>Check out our analyses</p>
                  <ButtonComponent
                    text={"View"}
                    handler={() => {
                      navigate("/analysis-statistics");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SliderCarouselWhatWeDo;
