import TickIcon from "../../../../../../assets/icons/tick.svg";
import BlueTickIcon from "../../../../../../assets/icons/BlueTick.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./accessAnalysisSection.module.css";

function AccessAnalysisSection(props) {
  return (
    <div className={styles.how_cont}>
      <div className={styles.how_heading_cont}>
        <h2>Alright, How to Do Hybrid Analysis on a Stock?</h2>
      </div>

      <div className={styles.how_cont_sub}>
        <div className={`${styles.how_text_cont} ${styles.how_text_cont_left}`}>
          <h2>The Strategic Process</h2>
          <ul>
            <li>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                Stocks are analyzed fundamentally using metrics like{" "}
                <b>EPS, PE ratio, ROE, dividend yield, and DE ratio.</b> Peer
                comparisons, profit and loss statements, balance sheets, cash
                flows, and FII and DII investments are also assessed for a
                complete analysis.
              </span>
            </li>

            <li>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                Strongly filtered stocks from fundamental analysis are subjected
                to technical analysis using{" "}
                <b>price action, volume patterns, and breakout signals.</b>
              </span>
            </li>

            <li>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                Tracking significant <b>news impacting stocks</b> and analyzing
                their potential using both fundamental and technical insights.
              </span>
            </li>

            <li style={{ marginBottom: "0px" }}>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                Implementing risk management strategies, such as assessing{" "}
                <b>
                  risk-to-reward ratios, pinpointing entry points, setting
                  profit targets,
                </b>{" "}
                and establishing protective stop-loss levels.
              </span>
            </li>
          </ul>
        </div>

        <div className={styles.how_text_cont}>
          <h2>The Complex Reality</h2>
          <ul>
            <li>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                Analyzing a single stock with all these factors typically takes
                over an hour.
              </span>
            </li>

            <li>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                Evaluating all{" "}
                <b style={{ color: "#000", fontWeight: "700" }}>2000+ stocks</b>{" "}
                in the NSE to identify the top 6 high-potential stocks weekly is
                extremely challenging.
              </span>
            </li>

            <li>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                That's where "Charteey" steps in. We meticulously analyze over
                2000 stocks each week to pinpoint and deliver the top{" "}
                <b style={{ color: "#000", fontWeight: "700" }}>
                  6 high-potential stocks via our mobile and web app.
                </b>
              </span>
            </li>

            <li style={{ marginBottom: "0px" }}>
              <img src={TickIcon} alt="tick-icon" />
              <span>
                Experience our service for Rs. 299 per year -{" "}
                <b style={{ color: "#000", fontWeight: "700" }}>
                  less than a rupee a day
                </b>{" "}
                - with a 7-day 100% money-back guarantee.
              </span>
            </li>
          </ul>

          <div className={styles.one_btn_cont}>
            <button
              onClick={() =>
                props.cta("FUNNEL - Alright container CTA btn clicked")
              }
            >
              Get it for ₹299{" "}
              <div
                onClick={() =>
                  props.cta("FUNNEL - Alright container CTA btn clicked")
                }
              >
                <MdKeyboardArrowRight color="#000" size={25} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessAnalysisSection;
