import { motion, useScroll, useTransform } from "framer-motion";
import { FaDiagramPredecessor } from "react-icons/fa6";
import { FaHardDrive } from "react-icons/fa6";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import StatsMockup from "../../../../../assets/images/stats_mockup.svg";
import AnalysisMockup from "../../../../../assets/images/analysis_mockup.svg";
import ModuleMockup from "../../../../../assets/images/module_mockup.svg";
import { useState, useEffect } from "react";
import styles from "./serviceContainer.module.css";

function ServiceContainer() {
  const { scrollY } = useScroll();

  //OVERVIEW

  const scale = useTransform(
    scrollY,
    [1050, 1100, 1150, 1200, 1250, 1300, 1350],
    [0.1, 0.2, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const scaleMbl = useTransform(
    scrollY,
    [2000, 2050, 2100, 2150, 2200, 2250, 2300],
    [0.1, 0.2, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const stiffness = useTransform(
    scrollY,
    [1050, 1100, 1150, 1200, 1250, 1300, 1350],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const percentageHeight = useTransform(
    scrollY,
    [
      1310, 1410, 1510, 1610, 1710, 1810, 1910, 2010, 2110, 2210, 2310, 2410,
      2515,
    ],
    [
      "0%",
      "5%",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "75%",
      "80%",
      "90%",
      "114%",
    ]
  );

  const percentageHeightMbl = useTransform(
    scrollY,
    [
      2500, 2600, 2700, 2800, 2900, 3000, 3100, 3200, 3300, 3400, 3500, 3600,
      3700,
    ],
    [
      "0%",
      "5%",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "75%",
      "80%",
      "90%",
      "100%",
    ]
  );

  const statsDotScale = useTransform(
    scrollY,
    [1495, 1500, 1505, 1510, 1520, 1525, 1535],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const statsDotScaleMbl = useTransform(
    scrollY,
    [2480, 2485, 2490, 2495, 2505, 2510, 2520],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const breakoutDotScale = useTransform(
    scrollY,
    [1790, 1795, 1800, 1805, 1815, 1820, 1830],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const breakoutDotScaleMbl = useTransform(
    scrollY,
    [3140, 3145, 3150, 3155, 3165, 3170, 3180],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const trailingtDotScale = useTransform(
    scrollY,
    [2020, 2025, 2030, 2035, 2045, 2050, 2060],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const trailingtDotScaleMbl = useTransform(
    scrollY,
    [3310, 3315, 3320, 3325, 3335, 3340, 3350],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const imgScaleOne = useTransform(
    scrollY,
    [1760, 1790, 1820, 1850, 1880, 1910, 1930],
    [0, 0.1, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const imgScaleOneMbl = useTransform(
    scrollY,
    [2610, 2640, 2670, 2700, 2730, 2760, 2790],
    [0, 0.1, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const imgStiffnessOne = useTransform(
    scrollY,
    [1760, 1765, 1770, 1775, 1785, 1790, 1800],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const imgStiffnessOneMbl = useTransform(
    scrollY,
    [2610, 2640, 2670, 2700, 2730, 2760, 3790],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const insightsDotScale = useTransform(
    scrollY,
    [2345, 2350, 2355, 2360, 2370, 2375, 2385],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const insightsDotScaleMbl = useTransform(
    scrollY,
    [3585, 3590, 3595, 3600, 3610, 3615, 3625],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  //ANALYSIS

  const scaleAnalysis = useTransform(
    scrollY,
    [2150, 2200, 2250, 2300, 2350, 2400, 2450],
    [0.1, 0.2, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const scaleAnalysisMbl = useTransform(
    scrollY,
    [3700, 3750, 3800, 3850, 3900, 3950, 4000],
    [0.1, 0.2, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const stiffnessAnalysis = useTransform(
    scrollY,
    [2150, 2200, 2250, 2300, 2350, 2400, 2450],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const stiffnessAnalysisMbl = useTransform(
    scrollY,
    [3700, 3750, 3800, 3850, 3900, 3950, 4000],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const percentageHeightAnalysis = useTransform(
    scrollY,
    [
      2390, 2490, 2590, 2690, 2790, 2890, 2990, 3090, 3190, 3290, 3390, 3490,
      3590,
    ],
    [
      "0%",
      "5%",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "75%",
      "80%",
      "90%",
      "114%",
    ]
  );

  const percentageHeightAnalysisMbl = useTransform(
    scrollY,
    [
      4150, 4250, 4350, 4450, 4650, 4750, 4850, 4950, 5050, 5150, 5250, 5350,
      5450,
    ],
    [
      "0%",
      "5%",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "75%",
      "80%",
      "90%",
      "100%",
    ]
  );

  const imgScaleTwo = useTransform(
    scrollY,
    [2820, 2850, 2880, 2910, 2940, 2970, 3000],
    [0, 0.1, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const imgScaleTwoMbl = useTransform(
    scrollY,
    [4250, 4280, 4310, 4340, 4370, 4400, 4430],
    [0, 0.1, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const imgStiffnessTwo = useTransform(
    scrollY,
    [2820, 2850, 2880, 2910, 2940, 2970, 3000],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const imgStiffnessTwoMbl = useTransform(
    scrollY,
    [4250, 4280, 4310, 4340, 4370, 4400, 4430],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const statsDotScaleAnalysis = useTransform(
    scrollY,
    [2585, 2590, 2595, 2600, 2610, 2615, 2625],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const statsDotScaleAnalysisMbl = useTransform(
    scrollY,
    [4130, 4135, 4140, 4145, 4155, 4160, 4170],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const breakoutDotScaleAnalysis = useTransform(
    scrollY,
    [2880, 2885, 2890, 2895, 2905, 2910, 2920],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const breakoutDotScaleAnalysisMbl = useTransform(
    scrollY,
    [4885, 4890, 4895, 4900, 4910, 4915, 4925],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const trailingtDotScaleAnalysis = useTransform(
    scrollY,
    [3110, 3115, 3120, 3125, 3135, 3140, 3150],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const trailingtDotScaleAnalysisMbl = useTransform(
    scrollY,
    [5070, 5075, 5080, 5085, 5095, 5100, 5110],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const insightsDotScaleAnalysis = useTransform(
    scrollY,
    [3430, 3435, 3440, 3445, 3455, 3460, 3470],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const insightsDotScaleAnalysisMbl = useTransform(
    scrollY,
    [5315, 5320, 5325, 5330, 5340, 5345, 5355],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  //MODULES

  const scaleModules = useTransform(
    scrollY,
    [3250, 3300, 3350, 3400, 3450, 3500, 3550],
    [0.1, 0.2, 0.3, 0.5, 0.7, 0.9, 1]
  );
  const scaleModulesMbl = useTransform(
    scrollY,
    [5350, 5400, 5450, 5500, 5550, 5600, 5650],
    [0.1, 0.2, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const stiffnessModules = useTransform(
    scrollY,
    [3250, 3300, 3350, 3400, 3450, 3500, 3550],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const stiffnessModulesMbl = useTransform(
    scrollY,
    [5350, 5400, 5450, 5500, 5550, 5600, 5650],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const percentageHeightModules = useTransform(
    scrollY,
    [
      3470, 3570, 3670, 3770, 3870, 3970, 4070, 4170, 4270, 4370, 4470, 4570,
      4670,
    ],
    [
      "0%",
      "5%",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "75%",
      "80%",
      "90%",
      "114%",
    ]
  );

  const percentageHeightModulesMbl = useTransform(
    scrollY,
    [
      5830, 5930, 6030, 6130, 6230, 6330, 6430, 6530, 6630, 6730, 6830, 6930,
      7030,
    ],
    [
      "0%",
      "5%",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "75%",
      "80%",
      "90%",
      "100%",
    ]
  );

  const imgScaleThree = useTransform(
    scrollY,
    [3880, 3910, 3940, 3970, 4000, 4030, 4060],
    [0, 0.1, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const imgScaleThreeMbl = useTransform(
    scrollY,
    [5920, 5950, 5980, 6010, 6040, 6070, 6100],
    [0, 0.1, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const imgStiffnessThree = useTransform(
    scrollY,
    [3880, 3910, 3940, 3970, 4000, 4030, 4060],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const imgStiffnessThreeMbl = useTransform(
    scrollY,
    [5920, 5950, 5980, 6010, 6040, 6070, 6100],
    [100, 200, 300, 400, 500, 600, 700]
  );

  const statsDotScaleModules = useTransform(
    scrollY,
    [3675, 3680, 3685, 3690, 3700, 3705, 3715],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const statsDotScaleModulesMbl = useTransform(
    scrollY,
    [5810, 5815, 5820, 5825, 5835, 5840, 5850],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const breakoutDotScaleModules = useTransform(
    scrollY,
    [3950, 3955, 3960, 3965, 3975, 3980, 3990],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const breakoutDotScaleModulesMbl = useTransform(
    scrollY,
    [6470, 6475, 6480, 6485, 6495, 6500, 6510],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const trailingtDotScaleModules = useTransform(
    scrollY,
    [4180, 4185, 4190, 4195, 4205, 4210, 4220],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const trailingtDotScaleModulesMbl = useTransform(
    scrollY,
    [6650, 6655, 6660, 6665, 6675, 6680, 6690],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const insightsDotScaleModules = useTransform(
    scrollY,
    [4515, 4520, 4525, 4530, 4540, 4545, 4555],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const insightsDotScaleModulesMbl = useTransform(
    scrollY,
    [6895, 6900, 6905, 6910, 6920, 6925, 6935],
    [0.1, 0.2, 0.3, 1, 1.5, 1.3, 1]
  );

  const [scrollX, setScrollX] = useState(0);
  const [scrollYy, setScrollYy] = useState(0);
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollX(window.scrollX);
      setScrollYy(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // console.log(scrollYy);

  return (
    <section className={styles.service_container}>
      {/*OVERVIEW*/}
      <div className={styles.service_container_sub}>
        <div className={styles.circle_cont}>
          <motion.div
            className={styles.circle_outer}
            style={{
              scale: windowWidth <= 924 ? scaleMbl : scale,
              transition: {
                ease: [0, 0.71, 0.2, 1.01],
                stiffness,
              },
            }}
          >
            <div className={styles.circle_inner}>
              <motion.div
                className={styles.circle_inner_img_text}
                style={{
                  scale,
                  transition: { ease: [0, 0.71, 0.2, 1.01], stiffness },
                }}
              >
                <FaDiagramPredecessor size={30} color="#369eff" />
                <h2>Overview</h2>
              </motion.div>
            </div>
          </motion.div>
        </div>

        {windowWidth > 924 && (
          <div className={styles.overview_cont}>
            <div className={styles.overview_cont_left}>
              <motion.div className={styles.overview_content_one}>
                <h3>Statistics</h3>
                <p>
                  Experience our trading community's real-time profit/loss
                  statistics on our intuitive dashboard - Become part of our
                  expert insights!
                </p>
              </motion.div>

              <div className={styles.overview_content_one}>
                <div className={styles.overview_content_one}>
                  <motion.img
                    src={StatsMockup}
                    style={{
                      scale: imgScaleOne,
                      transition: {
                        ease: [0, 0.71, 0.2, 1.01],
                        imgStiffnessOne,
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.circle_line}>
              <motion.div
                className={styles.circle_line_in}
                style={{ height: percentageHeight }}
              >
                <motion.div
                  className={styles.line_dot_stats}
                  style={{
                    scale: statsDotScale,
                  }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_breakout}
                  style={{ scale: breakoutDotScale }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_trailing}
                  style={{ scale: trailingtDotScale }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_insights}
                  style={{ scale: insightsDotScale }}
                ></motion.div>
              </motion.div>
            </div>

            <div className={styles.overview_cont_right}>
              <div
                className={styles.overview_content_one}
                style={{ marginTop: "17rem" }}
              >
                <h3>Breakout</h3>
                <p>
                  Identify new breakout opportunities for profitable trades. Tap
                  into expert insights and analysis in our trading community.
                </p>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Trailing</h3>
                <p>
                  Monitor trending stocks with confirmed breakouts. Gain access
                  to comprehensive market analysis and trading strategies in our
                  community.
                </p>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Insights</h3>
                <p>
                  Unlock actionable insights and maximize trading potential with
                  our intuitive dashboard and comprehensive data visualization.
                </p>
              </div>
            </div>
          </div>
        )}

        {windowWidth <= 924 && (
          <div className={styles.overview_cont}>
            <div className={styles.circle_line}>
              <motion.div
                className={styles.circle_line_in}
                style={{ height: percentageHeightMbl }}
              ></motion.div>
            </div>

            <div className={styles.overview_cont_left}>
              <div className={styles.overview_content_one}>
                <h3>Statistics</h3>
                <p>
                  Experience our trading community's real-time profit/loss
                  statistics on our intuitive dashboard - Become part of our
                  expert insights!
                </p>
                <motion.div
                  className={styles.line_dot_stats}
                  style={{
                    scale: statsDotScaleMbl,
                  }}
                ></motion.div>
              </div>

              <div className={styles.overview_content_one}>
                <motion.img
                  src={StatsMockup}
                  style={{
                    scale: imgScaleOneMbl,
                    transition: {
                      ease: [0, 0.71, 0.2, 1.01],
                      imgStiffnessOneMbl,
                    },
                  }}
                />
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Breakout</h3>
                <p>
                  Identify new breakout opportunities for profitable trades. Tap
                  into expert insights and analysis in our trading community.
                </p>
                <motion.div
                  className={styles.line_dot_breakout}
                  style={{ scale: breakoutDotScaleMbl }}
                ></motion.div>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Trailing</h3>
                <p>
                  Monitor trending stocks with confirmed breakouts. Gain access
                  to comprehensive market analysis and trading strategies in our
                  community.
                </p>
                <motion.div
                  className={styles.line_dot_trailing}
                  style={{ scale: trailingtDotScaleMbl }}
                ></motion.div>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Insights</h3>
                <p>
                  Unlock actionable insights and maximize trading potential with
                  our intuitive dashboard and comprehensive data visualization.
                </p>
                <motion.div
                  className={styles.line_dot_insights}
                  style={{ scale: insightsDotScaleMbl }}
                ></motion.div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*ANALYSIS*/}
      <div
        className={styles.service_container_sub}
        style={{ marginTop: "5rem" }}
      >
        <div className={styles.circle_cont}>
          <motion.div
            className={styles.circle_outer}
            style={{
              scale: windowWidth <= 924 ? scaleAnalysisMbl : scaleAnalysis,
              transition: {
                ease: [0, 0.71, 0.2, 1.01],
                stiffness:
                  windowWidth <= 924 ? stiffnessAnalysisMbl : stiffnessAnalysis,
              },
              backgroundColor: "#24180f",
            }}
          >
            <div
              className={styles.circle_inner}
              style={{ backgroundColor: "#391a03" }}
            >
              <motion.div
                className={styles.circle_inner_img_text}
                style={{
                  scale: windowWidth <= 924 ? scaleAnalysisMbl : scaleAnalysis,
                  transition: {
                    ease: [0, 0.71, 0.2, 1.01],
                    stiffness:
                      windowWidth <= 924
                        ? stiffnessAnalysisMbl
                        : stiffnessAnalysis,
                  },
                }}
              >
                <FaMoneyBillTrendUp size={30} color="#ff802b" />
                <h2 style={{ color: "#ff802b" }}>Analysis</h2>
              </motion.div>
            </div>
          </motion.div>
        </div>

        {windowWidth > 924 && (
          <div className={styles.overview_cont}>
            <div className={styles.overview_cont_left}>
              <motion.div className={styles.overview_content_one}>
                <h3>Market</h3>
                <p>
                  Comprehensive stock market analysis providing insights on
                  trends, performance, and potential risks to aid informed
                  investment decisions.
                </p>
              </motion.div>

              <div className={styles.overview_content_one}>
                <div className={styles.overview_content_one}>
                  <motion.img
                    src={AnalysisMockup}
                    style={{
                      scale: imgScaleTwo,
                      transition: {
                        ease: [0, 0.71, 0.2, 1.01],
                        imgStiffnessTwo,
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.circle_line}>
              <motion.div
                className={styles.circle_line_in}
                style={{
                  height: percentageHeightAnalysis,
                  backgroundColor: "#763205",
                }}
              >
                <motion.div
                  className={styles.line_dot_stats}
                  style={{
                    scale: statsDotScaleAnalysis,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_breakout}
                  style={{
                    scale: breakoutDotScaleAnalysis,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_trailing}
                  style={{
                    scale: trailingtDotScaleAnalysis,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_insights}
                  style={{
                    scale: insightsDotScaleAnalysis,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
              </motion.div>
            </div>

            <div className={styles.overview_cont_right}>
              <div
                className={styles.overview_content_one}
                style={{ marginTop: "17rem" }}
              >
                <h3>Fundamentals</h3>
                <p>
                  Fundamental stock analysis assesses a company's financial
                  health, management, and industry position to determine its
                  intrinsic value and potential.
                </p>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Price action</h3>
                <p>
                  Technical stock analysis utilizes past price and volume data
                  to forecast future price movements, identifying buy and sell
                  opportunities
                </p>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Risk management</h3>
                <p>
                  Risk management in stock trading involves strategies to
                  mitigate potential losses and protect investments through
                  careful planning.
                </p>
              </div>
            </div>
          </div>
        )}

        {windowWidth <= 924 && (
          <div className={styles.overview_cont}>
            <div className={styles.circle_line}>
              <motion.div
                className={styles.circle_line_in}
                style={{
                  height: percentageHeightAnalysisMbl,
                  backgroundColor: "#763205",
                }}
              ></motion.div>
            </div>

            <div className={styles.overview_cont_left}>
              <div className={styles.overview_content_one}>
                <h3>Market</h3>
                <p>
                  Comprehensive stock market analysis providing insights on
                  trends, performance, and potential risks to aid informed
                  investment decisions.
                </p>
                <motion.div
                  className={styles.line_dot_stats}
                  style={{
                    scale: statsDotScaleAnalysisMbl,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
              </div>

              <div className={styles.overview_content_one}>
                <motion.img
                  src={AnalysisMockup}
                  style={{
                    scale: imgScaleTwoMbl,
                    transition: {
                      ease: [0, 0.71, 0.2, 1.01],
                      imgStiffnessTwoMbl,
                    },
                  }}
                />
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Fundamentals</h3>
                <p>
                  Fundamental stock analysis assesses a company's financial
                  health, management, and industry position to determine its
                  intrinsic value and potential.
                </p>
                <motion.div
                  className={styles.line_dot_breakout}
                  style={{
                    scale: breakoutDotScaleAnalysisMbl,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Price action</h3>
                <p>
                  Technical stock analysis utilizes past price and volume data
                  to forecast future price movements, identifying buy and sell
                  opportunities
                </p>
                <motion.div
                  className={styles.line_dot_trailing}
                  style={{
                    scale: trailingtDotScaleAnalysisMbl,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Risk management</h3>
                <p>
                  Risk management in stock trading involves strategies to
                  mitigate potential losses and protect investments through
                  careful planning.
                </p>
                <motion.div
                  className={styles.line_dot_insights}
                  style={{
                    scale: insightsDotScaleAnalysisMbl,
                    border: "2px solid #763205",
                  }}
                ></motion.div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*MODULES*/}
      <div
        className={styles.service_container_sub}
        style={{ marginTop: "5rem" }}
      >
        <div className={styles.circle_cont}>
          <motion.div
            className={styles.circle_outer}
            style={{
              scale: windowWidth <= 924 ? scaleModulesMbl : scaleModules,
              transition: {
                ease: [0, 0.71, 0.2, 1.01],
                stiffness:
                  windowWidth <= 924 ? stiffnessModulesMbl : stiffnessModules,
              },
              backgroundColor: "#131E1A",
            }}
          >
            <div
              className={styles.circle_inner}
              style={{ backgroundColor: "#0f291e" }}
            >
              <motion.div
                className={styles.circle_inner_img_text}
                style={{
                  scale: windowWidth <= 924 ? scaleModulesMbl : scaleModules,
                  transition: {
                    ease: [0, 0.71, 0.2, 1.01],
                    stiffness:
                      windowWidth <= 924
                        ? stiffnessModulesMbl
                        : stiffnessModules,
                  },
                }}
              >
                <FaHardDrive size={30} color="#3cb179" />
                <h2 style={{ color: "#3cb179" }}>Modules</h2>
              </motion.div>
            </div>
          </motion.div>
        </div>

        {windowWidth > 924 && (
          <div className={styles.overview_cont}>
            <div className={styles.overview_cont_left}>
              <motion.div className={styles.overview_content_one}>
                <h3>Courses</h3>
                <p>
                  Master the stock market with our comprehensive course,
                  covering strategies, analysis, and trading techniques for
                  financial success.
                </p>
              </motion.div>

              <div className={styles.overview_content_one}>
                <div className={styles.overview_content_one}>
                  <motion.img
                    src={ModuleMockup}
                    style={{
                      scale: imgScaleThree,
                      transition: {
                        ease: [0, 0.71, 0.2, 1.01],
                        imgStiffnessThree,
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.circle_line}>
              <motion.div
                className={styles.circle_line_in}
                style={{
                  height: percentageHeightModules,
                  backgroundColor: "#1b543a",
                }}
              >
                <motion.div
                  className={styles.line_dot_stats}
                  style={{
                    scale: statsDotScaleModules,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_breakout}
                  style={{
                    scale: breakoutDotScaleModules,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_trailing}
                  style={{
                    scale: trailingtDotScaleModules,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
                <motion.div
                  className={styles.line_dot_insights}
                  style={{
                    scale: insightsDotScaleModules,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
              </motion.div>
            </div>

            <div className={styles.overview_cont_right}>
              <div
                className={styles.overview_content_one}
                style={{ marginTop: "17rem" }}
              >
                <h3>Hybrid analysis</h3>
                <p>
                  Unlock the secrets of stock market success with our hybrid
                  analysis approach, blending fundamentals and technicals for
                  strategic trading mastery.
                </p>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Analyses</h3>
                <p>
                  Access hybrid analysis insights in our stock market course,
                  tailored for both free and paid users for informed trading
                  decisions.
                </p>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Support</h3>
                <p>
                  Round-the-clock support ensures clarity and guidance for users
                  of our hybrid analysis stock market course.
                </p>
              </div>
            </div>
          </div>
        )}

        {windowWidth <= 924 && (
          <div className={styles.overview_cont}>
            <div className={styles.circle_line}>
              <motion.div
                className={styles.circle_line_in}
                style={{
                  height: percentageHeightModulesMbl,
                  backgroundColor: "#1b543a",
                }}
              ></motion.div>
            </div>

            <div className={styles.overview_cont_left}>
              <div className={styles.overview_content_one}>
                <h3>Courses</h3>
                <p>
                  Master the stock market with our comprehensive course,
                  covering strategies, analysis, and trading techniques for
                  financial success.
                </p>
                <motion.div
                  className={styles.line_dot_stats}
                  style={{
                    scale: statsDotScaleModulesMbl,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
              </div>

              <div className={styles.overview_content_one}>
                <motion.img
                  src={ModuleMockup}
                  style={{
                    scale: imgScaleThreeMbl,
                    transition: {
                      ease: [0, 0.71, 0.2, 1.01],
                      imgStiffnessThreeMbl,
                    },
                  }}
                />
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Hybrid analysis</h3>
                <p>
                  Unlock the secrets of stock market success with our hybrid
                  analysis approach, blending fundamentals and technicals for
                  strategic trading mastery.
                </p>
                <motion.div
                  className={styles.line_dot_breakout}
                  style={{
                    scale: breakoutDotScaleModulesMbl,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Analyses</h3>
                <p>
                  Access hybrid analysis insights in our stock market course,
                  tailored for both free and paid users for informed trading
                  decisions.
                </p>
                <motion.div
                  className={styles.line_dot_trailing}
                  style={{
                    scale: trailingtDotScaleModulesMbl,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
              </div>

              <div
                className={styles.overview_content_one}
                style={{ marginTop: "3rem" }}
              >
                <h3>Support</h3>
                <p>
                  Round-the-clock support ensures clarity and guidance for users
                  of our hybrid analysis stock market course.
                </p>
                <motion.div
                  className={styles.line_dot_insights}
                  style={{
                    scale: insightsDotScaleModulesMbl,
                    border: "2px solid #1b543a",
                  }}
                ></motion.div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ServiceContainer;
