import styles from "./funnelPageForAnalysis.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../components/store/context/authContextProvider";
import CharteeyImg from "../../../../assets/images/charteey_phn.svg";
import CharteeyImgLow from "../../../../assets/images/charteey_phn_low.png";
import TickIcon from "../../../../assets/icons/tick.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import Logo from "../../../../assets/images/logo.svg";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WhatWillChange from "./sections/whatWillChange/whatWillChange";
import WhyHybrid from "./sections/whyHybrid/whyHybrid";
import SecretSection from "./sections/secretSection/secretSection";
import AccessAnalysisSection from "./sections/accessAnalysisSection/accessAnalysisSection";
import ReviewSection from "./sections/reviewSection/reviewSection";
import MoneyBackSection from "./sections/moneyBackSection/moneyBackSection";
import FooterContainer from "./sections/footerContainer/footerContainer";
import useProgressiveImg from "../../../../components/progressiveImg/progressiveImg";
import { event } from "../../../../components/commonFunction/commonFunction";

function FunnelPageForAnalysis() {
  const {
    setUserSelectedCourseFromPricingPage,
    setUserSelectedCourse,
    setUpdatingPlan,
    ipAddress,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(undefined);
  const [buyNowLoader, setBuyNowLoader] = useState(false);
  const [src, { blur }] = useProgressiveImg(CharteeyImgLow, CharteeyImg);
  const [timeRemaining, setTimeRemaining] = useState(15 * 60);
  const [event2325Triggered, setEvent2325Triggered] = useState(false);
  const [event4704Triggered, setEvent4704Triggered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to update the timer
    const updateTimer = () => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          // Reset the timer to 15 minutes
          return 15 * 60;
        } else {
          // Decrement the time remaining
          return prevTime - 1;
        }
      });
    };

    // Set interval to update the timer every second
    const timerId = setInterval(updateTimer, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (ipAddress) {
      console.log(ipAddress);
      const myFunction3Sec = () => {
        event("StayEvent", "FunnelPage - user stayed 3secs", ipAddress);
      };

      // const myFunction10Sec = () => {
      //   event("StayEvent", "FunnelPage - user stayed 10secs");
      // };

      const timer1 = setTimeout(myFunction3Sec, 3000);

      // const timer2 = setTimeout(myFunction10Sec, 10000);

      return () => {
        clearTimeout(timer1);
        // clearTimeout(timer2);
      };
    }
  }, [ipAddress]);

  useEffect(() => {
    const handleScroll = () => {
      if (!event2325Triggered && window.scrollY > 2325) {
        event("ScrollEvent", "User scrolled to carousel", ipAddress);
        setEvent2325Triggered(true);
      }

      if (!event4704Triggered && window.scrollY > 4704) {
        event("ScrollEvent", "User scrolled to money back", ipAddress);
        setEvent4704Triggered(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ipAddress, event2325Triggered, event4704Triggered]);

  // Calculate minutes and seconds
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  // Format the time string
  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  function CTAClickHandler(msg) {
    event("BtnClick", msg, ipAddress);
    setUserSelectedCourseFromPricingPage("analysis");
    setUserSelectedCourse("analysis");
    setUpdatingPlan(false);
    navigate("/checkout");
  }

  console.log(window.scrollY);

  return (
    <div className={styles.course_detail_container_main}>
      <Helmet>
        <title>Charteey - Hybrid Analysis</title>
        <link
          rel="canonical"
          href="https://charteey.com/unlock-stock-market-success-with-hybrid-analysis"
        />
        <meta
          name="description"
          content="Discover top stock picks weekly with Charteey’s hybrid analysis. Master trading and boost your portfolio with our expert insights."
        />
      </Helmet>
      <div className={styles.course_detail_container_main_sub}>
        <div className={styles.sec_one}>
          <div className={styles.sec_one_text_cont}>
            <div className={styles.sec_one_head_box}>
              <h4>Finding Winning Stocks is Now Easier Than Ever</h4>
            </div>
            <h2>
              Skyrocket your{" "}
              <span style={{ color: "#0067de" }}>portfolio 10X </span>with our
              advanced hybrid analysis and proven strategies
            </h2>
            <h4>Build momentum towards financial success.</h4>
          </div>

          <div className={styles.one_img_why_cont}>
            <div className={styles.one_img_cont}>
              <img
                src={src}
                style={{
                  filter: blur ? "blur(20px)" : "none",
                  transition: blur ? "none" : "filter 0.3s ease-out",
                }}
                alt={"charteey-phone-img"}
              />
            </div>
            <div className={styles.one_why_cont}>
              <div className={styles.why_heading_cont}>
                <h2>With Charteey, You Will...</h2>
              </div>
              <div className={styles.one_why_cont_box}>
                <ul>
                  <li>
                    <div>
                      <img src={TickIcon} alt="tick-icon" />
                    </div>
                    <span>
                      Unlock Insider Secrets to Consistently Pick Winning
                      Stocks.
                    </span>
                  </li>

                  <li>
                    <div>
                      <img src={TickIcon} alt="tick-icon" />
                    </div>
                    <span>
                      Master Both Fundamental and Technical Analysis
                      Effortlessly.
                    </span>
                  </li>

                  <li>
                    <div>
                      <img src={TickIcon} alt="tick-icon" />
                    </div>
                    <span>
                      Accelerate Your Wealth Building with Proven Trading
                      Strategies.
                    </span>
                  </li>

                  <li>
                    <div>
                      <img src={TickIcon} alt="tick-icon" />
                    </div>
                    <span>
                      Maximize Your Profits with Expert, Data-Driven Insights.
                    </span>
                  </li>

                  <li style={{ paddingBottom: "0px" }}>
                    <div>
                      <img src={TickIcon} alt="tick-icon" />
                    </div>
                    <span>
                      Get Instantly Notified of High Potential Stock Breakouts.
                    </span>
                  </li>
                </ul>
              </div>

              <div className={styles.one_btn_cont}>
                <button
                  onClick={() =>
                    CTAClickHandler(
                      "FUNNEL - skyrocket container CTA btn clicked"
                    )
                  }
                >
                  Get it for ₹299{" "}
                  <div
                    onClick={() =>
                      CTAClickHandler(
                        "FUNNEL - skyrocket container CTA btn clicked"
                      )
                    }
                  >
                    <MdKeyboardArrowRight color="#000" size={25} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <WhyHybrid cta={CTAClickHandler} />
        <SecretSection cta={CTAClickHandler} />
        <AccessAnalysisSection cta={CTAClickHandler} />
        <WhatWillChange cta={CTAClickHandler} />
        <ReviewSection cta={CTAClickHandler} />
        <MoneyBackSection cta={CTAClickHandler} />
        <FooterContainer />
      </div>

      <div className={styles.buy_now_cont}>
        <div className={styles.buy_now_cont_sub}>
          <div className={styles.price_cont}>
            <img src={Logo} alt="logo" />
            <div>
              <div className={styles.strike_price_cont}>
                <h4 className={styles.strike_price_text}>₹299</h4>
                <h4 className={styles.offer_price_text}> ₹599</h4>
              </div>
              <h2>{`Offer ends in ${formattedTime}`}</h2>
            </div>
          </div>

          <button
            onClick={() =>
              CTAClickHandler("FUNNEL - Common bottom CTA btn clicked")
            }
          >
            Get it for ₹299
          </button>
        </div>
      </div>
    </div>
  );
}

export default FunnelPageForAnalysis;
