import styles from "./reviewsContainer.module.css";
import Marquee from "react-fast-marquee";
import { MdVerified } from "react-icons/md";
import UserAvatar from "../../../../../assets/icons/userAvatar.png";

function ReviewsContainer() {
  const reviewData = [
    {
      name: "Vijun",
      id: "Maharashtra",
      gender: "male",
      review:
        "I have attended many trading courses, but this one stands out due to its comprehensive content and expert guidance. The instructor's in-depth market analysis is particularly impressive and has greatly enhanced my trading skills. Highly recommended for serious traders.",
    },
    {
      name: "Harikesh",
      id: "Tamilnadu",
      gender: "male",
      review:
        "Hey folks! Just finished this trading course and I must say it’s awesome! The way the trainer explains everything, even complex concepts, is simply amazing. Learned so much and my confidence has skyrocketed. Do join if you’re serious about trading.",
    },
    {
      name: "Jasleen",
      id: "Kerala",
      gender: "male",
      review:
        "The course is meticulously designed to cover all aspects of trading, from basics to advanced strategies. The live analysis sessions are a goldmine, providing practical insights that are immediately applicable. A must-attend for anyone looking to excel in trading.",
    },
    {
      name: "Roopa",
      id: "Karnataka",
      gender: "male",
      review:
        "Wow! This course is a game changer. The analysis shared by the instructor is top-notch and very insightful. I've seen a significant improvement in my trading performance. Definitely worth every penny!",
    },
    {
      name: "Jaswinder",
      id: "Madhya Pradesh",
      gender: "male",
      review:
        "So I took this trading course and it’s been an amazing journey. The instructor breaks down the analysis in such a simple manner that even a beginner like me could grasp it easily. Really enjoyed it and learned a lot!",
    },
    {
      name: "Indrakshi",
      id: "Tamilnadu",
      gender: "male",
      review:
        "Excellent course with superb market analysis. The trainer is highly knowledgeable and explains concepts clearly. My trading skills have improved immensely. Highly recommend!",
    },
    {
      name: "Bhuvanesh",
      id: "Telangana",
      gender: "male",
      review:
        "The detailed market analysis and strategy sessions are the highlight of this course. The instructor’s expertise and ability to explain complex scenarios in an understandable manner is commendable. An invaluable resource for traders.",
    },
    {
      name: "Jegan",
      id: "Karnataka",
      gender: "male",
      review:
        "I am truly grateful for the insights and knowledge gained from this trading course. The live analysis sessions are particularly beneficial and have helped me navigate the markets with more confidence and accuracy. Highly recommended!",
    },
    {
      name: "Sariga",
      id: "Telangana",
      gender: "male",
      review:
        "This course has completely transformed my approach to trading. The instructor’s analysis is spot-on and incredibly educational. I feel much more confident in my trades now. If you’re serious about trading, you have to join this course!",
    },
    {
      name: "Vishnu",
      id: "Tamilnadu",
      gender: "male",
      review:
        "Joining this trading course was one of the best decisions I made. The in-depth analysis and personal attention from the instructor have really boosted my trading knowledge and skills. It’s a great learning experience that I’d recommend to everyone.",
    },
  ];

  return (
    <section className={styles.reviews_container}>
      <div className={styles.reviews_container_sub}>
        <h2 className={styles.review_main_heading}>Our users review</h2>
        <Marquee pauseOnHover={true}>
          <div className={styles.marquee_cont_sub}>
            {reviewData.map((item) => {
              return (
                <div className={styles.marquee_card}>
                  <div className={styles.marquee_card_top}>
                    <div className={styles.profile_circle}>
                      <img
                        src={UserAvatar}
                        alt="profile-pic"
                        width={55}
                        height={55}
                      />
                    </div>

                    <div className={styles.user_detail_cont}>
                      <p className={styles.name}>{item.name}</p>
                      <p className={styles.id}>{`@${item.id}`}</p>
                    </div>

                    <div className={styles.insta_icon}>
                      <MdVerified size={25} color={"#0c969a"} />
                    </div>
                  </div>

                  <div className={styles.marquee_card_bottom}>
                    <span className={styles.review_text}>{item.review}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </Marquee>
      </div>
    </section>
  );
}

export default ReviewsContainer;
