import styles from "../privacyPolicy/privacyPolicyPage.module.css";
import stylesOne from "./faq.module.css";

function FaqPage() {
  return (
    <section className={styles.privacy_page_container}>
      <div className={stylesOne.top_image_container}>
        <span>FAQ</span>
        <span>
          Answers to common queries about trading analyses, community
          engagement, and platform usage.
        </span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span>What is Charteey?</span>
          <span>
            Charteey is an online platform where traders can access trading
            courses, share analyses, and engage with a community of like-minded
            individuals interested in the stock market.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Who provides the trading analysis on this platform?</span>
          <span>
            The trading analysis on Charteey is provided by experienced traders
            and analysts within our community. While we strive to ensure the
            accuracy and reliability of the information shared, users should
            conduct their own research and consult with a financial advisor
            before making investment decisions.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Are the trading analyses guaranteed to be accurate?</span>
          <span>
            No, the trading analyses provided on this platform are for
            informational purposes only and do not guarantee any specific
            outcomes. Market conditions can change rapidly, and past performance
            is not indicative of future results. Users should exercise caution
            and diligence in their trading activities.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>
            How can I use the trading analyses on this platform effectively?
          </span>
          <span>
            We encourage users to use the trading analyses as a tool for
            educational purposes and as a basis for conducting their own
            research. It's essential to consider your individual risk tolerance,
            investment goals, and market conditions before making any trading
            decisions.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>
            Is Charteey affiliated with any brokerage firms or financial
            institutions?
          </span>
          <span>
            No, Charteey is an independent platform and is not affiliated with
            any brokerage firms or financial institutions. We do not endorse any
            specific brokerage services or products.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>
            How can I contribute to the trading community on this platform?
          </span>
          <span>
            We welcome contributions from members of our community. You can
            share your trading analyses, participate in discussions, and engage
            with other members to enhance the overall experience for everyone.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>How can I contact Charteey for support or inquiries?</span>
          <span>
            If you have any questions, concerns, or feedback, please don't
            hesitate to contact us at support@charteey.com. We're here to help
            and strive to provide the best possible experience for our community
            members.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Changes</span>
          <span>
            Charteey reserves the right, at its sole discretion, to modify or
            replace these Terms at any time. If a revision is material, we will
            provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Disclaimer Regarding Market Risk</span>
          <span>
            Please note that all analyses, information, and content provided on
            this website, including but not limited to stock analyses, trading
            courses, and educational materials, are for informational purposes
            only. The analyses shared are subject to market risk, and the
            trading of stocks involves substantial risk of loss and is not
            suitable for all investors. Charteey does not guarantee the
            accuracy, completeness, or timeliness of any information provided on
            this website. Users are solely responsible for their own investment
            decisions and should seek the advice of a qualified financial
            advisor before making any investment. By accessing or using this
            website, you acknowledge and agree that Charteey is not responsible
            for any losses incurred as a result of your reliance on the
            information provided on this website. Charteey shall not be liable
            for any direct, indirect, incidental, special, consequential, or
            punitive damages arising out of or related to your use of this
            website or any content available on this website.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Contact Us</span>
          <span>
            If you have any questions about these Terms, please contact us at
            support@charteey.com.
          </span>
        </div>

        <div className={styles.head_date_container}>
          <span>Last updated :</span>
          <span> March 12, 2024</span>
        </div>
      </div>
    </section>
  );
}

export default FaqPage;
