import styles from "../privacyPolicy/privacyPolicyPage.module.css";
import stylesOne from "./aboutUs.module.css";

function AboutUs() {
  return (
    <section className={styles.privacy_page_container}>
      <div className={stylesOne.top_image_container}>
        <span>About us</span>
        <span></span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span></span>
          <span>
            Welcome to Charteey, a thriving community founded by Kesavan and
            Ruban under the umbrella of Synerixs. We are passionate individuals
            with diverse expertise, dedicated to empowering individuals in the
            realm of stock market trading and digital technology.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Meet Kesavan: The Chartist</span>
          <span>
            With a background in full-stack development, Kesavan brings a unique
            perspective to stock market analysis. His proficiency in web and
            mobile app development is complemented by his expertise as a
            chartist, where he leverages technical indicators and chart patterns
            to identify potential trading opportunities. Kesavan's analytical
            approach and attention to detail make him a valuable asset in
            deciphering market trends and providing actionable insights to our
            community members.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Meet Ruban: The Fundamental Analyst</span>
          <span>
            As a digital marketer and fundamental stock analyst, Ruban brings a
            wealth of knowledge in market research and strategic analysis. His
            keen eye for detail and deep understanding of company fundamentals
            enable him to uncover promising investment opportunities with
            long-term growth potential. Ruban's dedication to thorough research
            and data-driven decision-making equips our community members with
            the essential tools to evaluate investment prospects and build
            sustainable portfolios.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Our Vision</span>
          <span>
            At Charteey, we believe in democratizing access to financial markets
            and technological innovation. Whether you're a seasoned investor or
            just starting out, our mission is to provide comprehensive
            resources, insightful analysis, and a supportive community to help
            you navigate the complexities of trading and technology.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Why Choose Us?</span>
          <span>
            Expertise: Our team comprises seasoned professionals with years of
            experience in their respective fields, ensuring you receive
            top-notch guidance and support.
          </span>
          <span
            style={{
              color: "#c9c8c7",
              fontSize: "0.9rem",
              marginTop: "0.3rem",
            }}
          >
            Community: Join a vibrant community of like-minded individuals who
            share a passion for trading, learning, and growth.
          </span>
          <span
            style={{
              color: "#c9c8c7",
              fontSize: "0.9rem",
              marginTop: "0.3rem",
            }}
          >
            Comprehensive Services: From stock market analysis and trading
            education to web and mobile app development and digital marketing,
            we offer a wide range of services to cater to your needs.
          </span>
          <span
            style={{
              color: "#c9c8c7",
              fontSize: "0.9rem",
              marginTop: "0.3rem",
            }}
          >
            Commitment to Excellence: We are committed to delivering excellence
            in everything we do, from the quality of our content to the level of
            support we provide to our members and clients.
          </span>
        </div>

        <div
          className={styles.head_content_container}
          style={{ marginBottom: "2rem" }}
        >
          <span>Get in Touch</span>
          <span>
            Ready to embark on your trading journey or kickstart your digital
            presence? Reach out to us today to learn more about our services,
            join the Charteey community, or discuss how we can help bring your
            ideas to life.
          </span>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
