import JoinCommunityContainer from "./components/JoinCommunityContainer/joinCommunityContainer";
import BannerContainer from "./components/bannerContainer/bannerContainer";
import BannerContainerOne from "./components/bannerContainer/bannerContainerOne";
import FooterContainer from "./components/footerContainer/footerContainer";
import FreeCourseContainer from "./components/freeCourseContainer/freeCourseContainer";
import ReviewsContainer from "./components/reviewsContainer/reviewsContainer";
import ServiceContainer from "./components/servicesContainer/serviceContainer";
import TacticsContainer from "./components/tacticsContainer/tacticsContainer";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import whatsappIcon from "../../../assets/icons/whatsappIcon.png";
import styles from "../../../components/layout/main-navigation.module.css";

function HomePage(props) {
  const defaultMessage = encodeURIComponent(
    "Hey there! 😊 Interested in what you offer! Could you give me some details?"
  );
  const phoneNumber = "91 8667882137";

  const openWhatsApp = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${defaultMessage}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>Charteey</title>
        <link rel="canonical" href="https://charteey.com/" />
        <meta
          name="description"
          content="Unlock trading success with our expert-led community. Master price action, fundamentals, and analysis for profitable trades"
        />
      </Helmet>
      <BannerContainer />
      <TacticsContainer />
      <ServiceContainer />
      <ReviewsContainer />
      <JoinCommunityContainer />
      <FreeCourseContainer />
      <FooterContainer />
      <motion.a
        className={styles.whatsappIcon}
        href="#"
        onClick={openWhatsApp}
        whileHover={{
          scale: 1.05,
        }}
      >
        <motion.img
          src={whatsappIcon}
          alt="WhatsApp icon"
          whileHover={{
            scale: 1.05,
          }}
        />
      </motion.a>
    </>
  );
}

export default HomePage;
