import HybridImg from "../../../../../../assets/images/hybrid.svg";
import styles from "./whyHybrid.module.css";

function WhyHybrid() {
  return (
    <div className={styles.why_hybrid_cont}>
      <div className={styles.why_hybrid_cont_sub}>
        <div className={styles.hybrid_cont_left}>
          <h2>Why Hybrid Analysis?</h2>

          <div>
            <span>
              <b>The fact is —</b> buying a stock without deep analysis is like
              jumping from a plane without a parachute.
            </span>
            <span>
              <b>That’s Where “Hybrid Analysis” Comes In.</b>
            </span>
            <span>
              A Hybrid Analysis is like a "Parachute" that ensures your stocks
              safely land on target. It lowers risk and helps your portfolio
              grow steadily, increasing your profits.
            </span>
          </div>
        </div>

        <div className={styles.why_img_cont}>
          <img src={HybridImg} alt="hybrid-img" />
        </div>
      </div>
    </div>
  );
}

export default WhyHybrid;
