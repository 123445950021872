import React, { useState, useEffect } from "react";

const TypingTextSingle = ({ text, seconds, textSpeed }) => {
  const [displayText, setDisplayText] = useState("");
  const [showCursor, setShowCursor] = useState(false);
  const [typingComplete, setTypingComplete] = useState(false);
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let index = 0;
    const typingTimeout = setTimeout(() => {
      const interval = setInterval(() => {
        if (index <= text.length) {
          setDisplayText(text.substring(0, index));
          index++;
        } else {
          clearInterval(interval);
          setTypingComplete(true); // Set typing completion flag
        }
      }, textSpeed || 100);
      // Setup cursor interval after 5 seconds
      const cursorInterval = setInterval(() => {
        setShowCursor((prev) => !prev);
      }, 300);
      return () => {
        clearInterval(interval);
        clearInterval(cursorInterval);
      };
    }, seconds); // Start typing after 5 seconds
    return () => clearTimeout(typingTimeout);
  }, [text]);

  useEffect(() => {
    // Hide cursor when typing is complete
    if (typingComplete) {
      setShowCursor(false);
    }
  }, [typingComplete]);

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      {displayText}
      {!typingComplete && showCursor && windowWidth > 705 && <span>_</span>}
    </span>
  );
};

export default TypingTextSingle;
