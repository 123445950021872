import React, { Fragment } from "react";
import { useState, useEffect, useContext } from "react";
import Logo from "../../assets/images/logo.svg";
import UserAvatar from "../../assets/icons/userAvatar.png";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import styles from "./main-navigation.module.css";
import { event as even } from "../commonFunction/commonFunction";
import { getAuthToken } from "../../utils/auth/auth";
import { AuthContext } from "../store/context/authContextProvider";
import ButtonComponent from "../buttonComponent/buttonComponent";

function MainNavigation(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setUserSelectedCourseFromPricingPage,
    setUserSelectedCourse,
    isValidToken,
    paid,
    logout,
    paymentStatus,
    userEmail,
    ipAddress,
  } = useContext(AuthContext);
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [isMenuBtnClicked, setIsMenuBtnClicked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function menuBtnHandler() {
    setIsMenuBtnClicked(!isMenuBtnClicked);
  }

  function signinBtnHandler(event) {
    event.preventDefault();
    navigate("/signin");
  }

  function signupBtnHandler(event) {
    event.preventDefault();
    even("BtnClick", "webpage-signup btn clicked", ipAddress);
    navigate("/signup");
  }

  function tryForFreeHandler(event) {
    event.preventDefault();
    even("BtnClick", "webpage-tryForFree btn clicked", ipAddress);
    navigate("/signup");
  }

  function logoutBtnHandler(event) {
    event.preventDefault();
    logout();
    navigate("/");
  }

  function menuClicked(e, route) {
    e.stopPropagation();
    if (route === "plan" || route === "normal-user-plan") {
      if (isValidToken && !paid) {
        even(
          "BtnClick",
          `webpage-navigation ourPlan btn clicked by registered use ${userEmail}`,
          "none"
        );
      }
    }
    if (route === "plan" || route === "normal-user-plan") {
      if (!isValidToken) {
        even(
          "BtnClick",
          `webpage-navigation ourPlan btn clicked by not registered user`,
          ipAddress
        );
      }
    }

    setIsMenuBtnClicked(false);
    navigate(`/${route}`);
  }

  function profileClickHandler() {
    if (isValidToken && paid) {
      navigate("/user-profile");
    } else {
      navigate("/normal-user-profile");
    }
  }

  return location.pathname !==
    "/unlock-stock-market-success-with-hybrid-analysis" ? (
    <div className={styles.header_outer}>
      <nav className={styles.nav}>
        {windowWidth >= 754 ? (
          <Fragment>
            <div className={styles.nav_left}>
              <div className={styles.logo_container}>
                <img src={Logo} alt="logo" width={25} height={25} />
              </div>
              {isValidToken && paid ? (
                <ul>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "paid-dashboard");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/paid-dashboard"
                            ? "#0c969a"
                            : "#fff",
                      }}
                    >
                      Dashboard
                    </a>
                  </motion.li>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "course");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/course" ? "#0c969a" : "#fff",
                      }}
                    >
                      Courses
                    </a>
                  </motion.li>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "analyses");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/analyses"
                            ? "#0c969a"
                            : "#fff",
                      }}
                    >
                      Analyses
                    </a>
                  </motion.li>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "upgrade");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/upgrade" ? "#0c969a" : "#fff",
                      }}
                    >
                      Upgrade
                    </a>
                  </motion.li>
                </ul>
              ) : isValidToken && !paid ? (
                <ul>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "normal-user-dashboard");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/normal-user-dashboard"
                            ? "#0c969a"
                            : "#fff",
                      }}
                    >
                      Dashboard
                    </a>
                  </motion.li>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "analysis-statistics");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/analysis-statistics"
                            ? "#0c969a"
                            : "#fff",
                      }}
                    >
                      Analyses
                    </a>
                  </motion.li>
                  {/* <motion.li
                  whileHover={{
                    scale: 1.05,
                  }}
                  onClick={(e) => {
                    menuClicked(e, "free-course");
                  }}
                >
                  <a
                    style={{
                      color:
                        location.pathname === "/free-course"
                          ? "#0c969a"
                          : "#fff",
                    }}
                  >
                    Free course
                  </a>
                </motion.li> */}
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "normal-user-plan");
                      // setUserSelectedCourseFromPricingPage("pro");
                      // setUserSelectedCourse("pro");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/normal-user-plan"
                            ? "#0c969a"
                            : "#fff",
                      }}
                    >
                      Our Plan
                    </a>
                  </motion.li>
                </ul>
              ) : (
                <ul>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "dashboard");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/dashboard"
                            ? "#0c969a"
                            : "#fff",
                      }}
                    >
                      Dashboard
                    </a>
                  </motion.li>
                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "analysis-statistics");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/analysis-statistics"
                            ? "#0c969a"
                            : "#fff",
                      }}
                    >
                      Analysis statistics
                    </a>
                  </motion.li>
                  {/* <motion.li
                  whileHover={{
                    scale: 1.05,
                  }}
                  onClick={(e) => {
                    menuClicked(e, "free-course");
                  }}
                >
                  <a
                    style={{
                      color:
                        location.pathname === "/free-course"
                          ? "#0c969a"
                          : "#fff",
                    }}
                  >
                    Free course
                  </a>
                </motion.li> */}

                  <motion.li
                    whileHover={{
                      scale: 1.05,
                    }}
                    onClick={(e) => {
                      menuClicked(e, "plan");
                      // setUserSelectedCourseFromPricingPage("pro");
                      // setUserSelectedCourse("pro");
                    }}
                  >
                    <a
                      style={{
                        color:
                          location.pathname === "/plan" ? "#0c969a" : "#fff",
                      }}
                    >
                      Our Plan
                    </a>
                  </motion.li>
                </ul>
              )}
            </div>
            <AnimatePresence mode="wait">
              {!isValidToken ? (
                <div className={styles.nav_right} key="login">
                  {location.pathname !== "/plan-details" &&
                    location.pathname !== "/checkout" && (
                      <ButtonComponent
                        text={"Sign Up"}
                        style={styles.button}
                        handler={signupBtnHandler}
                      />
                    )}
                  {location.pathname !== "/plan-details" &&
                    location.pathname !== "/checkout" && (
                      <ButtonComponent
                        text={"Signin"}
                        style={styles.button_transparent}
                        handler={signinBtnHandler}
                      />
                    )}
                </div>
              ) : (
                <div className={styles.nav_right} key="logout">
                  <motion.div
                    className={styles.profile_circle}
                    whileHover={{
                      scale: 1.05,
                      boxShadow: "0 0 8px 0.5px #313538",
                    }}
                    onClick={profileClickHandler}
                  >
                    <motion.img
                      src={UserAvatar}
                      alt="profile-pic"
                      width={35}
                      height={35}
                      whileHover={{
                        scale: 1.05,
                      }}
                    />
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </Fragment>
        ) : (
          <Fragment>
            <div className={styles.logo_container}>
              <img
                src={Logo}
                alt="Description of image"
                width={25}
                height={25}
              />
            </div>
            <div className={styles.menu_btn_and_btn}>
              {!isValidToken &&
                location.pathname !== "/plan-details" &&
                location.pathname !== "/checkout" && (
                  <ButtonComponent
                    text={"Try for free"}
                    style={styles.menu_option_btn_default}
                    handler={tryForFreeHandler}
                  />
                )}
              <div className={styles.menu_btn} onClick={menuBtnHandler}>
                {!isMenuBtnClicked ? (
                  <IoMenu size={25} color="#fff" />
                ) : (
                  <>
                    <IoCloseSharp size={25} color="#fff" />
                    <div
                      className={styles.menu_overlay}
                      onClick={menuBtnHandler}
                    >
                      <div className={styles.menu_container}>
                        {isValidToken && paid ? (
                          <div className={styles.menu_option_container}>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "paid-dashboard");
                              }}
                            >
                              <span>Dashboard</span>
                            </div>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "course");
                              }}
                            >
                              <span>Courses</span>
                            </div>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "analyses");
                              }}
                            >
                              <span>Analyses</span>
                            </div>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "upgrade");
                              }}
                            >
                              <span>Upgrade</span>
                            </div>

                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "user-profile");
                              }}
                            >
                              <span>Profile</span>
                            </div>
                          </div>
                        ) : isValidToken && !paid ? (
                          <div className={styles.menu_option_container}>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "/normal-user-dashboard");
                              }}
                            >
                              <span>Dashboard</span>
                            </div>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "analysis-statistics");
                              }}
                            >
                              <span>Analyses</span>
                            </div>
                            {/* <div
                            className={styles.menu_option}
                            onClick={(e) => {
                              menuClicked(e, "free-course");
                            }}
                          >
                            <span>Free course</span>
                          </div> */}
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "normal-user-plan");
                              }}
                            >
                              <span>Our Plan</span>
                            </div>

                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "normal-user-profile");
                              }}
                            >
                              <span>Profile</span>
                            </div>
                          </div>
                        ) : (
                          <div className={styles.menu_option_container}>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "dashboard");
                              }}
                            >
                              <span>Dashboard</span>
                            </div>
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "analysis-statistics");
                              }}
                            >
                              <span>Analyses</span>
                            </div>
                            {/* <div
                            className={styles.menu_option}
                            onClick={(e) => {
                              menuClicked(e, "free-course");
                            }}
                          >
                            <span>Free course</span>
                          </div> */}
                            <div
                              className={styles.menu_option}
                              onClick={(e) => {
                                menuClicked(e, "plan");
                              }}
                            >
                              <span>Our Plan</span>
                            </div>
                          </div>
                        )}
                        {!isValidToken ? (
                          <div className={styles.menu_option_btn_container}>
                            <ButtonComponent
                              text={"Signin"}
                              style={styles.menu_option_btn_transparent}
                              handler={signinBtnHandler}
                            />
                            <ButtonComponent
                              text={"Signup"}
                              style={styles.menu_option_btn_default}
                              handler={signupBtnHandler}
                            />
                          </div>
                        ) : (
                          <ButtonComponent
                            text={"Logout"}
                            style={styles.menu_option_btn_transparent}
                            handler={logoutBtnHandler}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </nav>
    </div>
  ) : (
    <></>
  );
}

export default MainNavigation;
