import { useState, useEffect } from "react";

function TypingTextArray() {
  const [words, setWords] = useState([
    "wealth",
    "passive income",
    "side hustle",
  ]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [speed, setSpeed] = useState(200);
  const [displayText, setDisplayText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [showCursor, setShowCursor] = useState(true);
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTyping) {
        typeText();
      } else {
        deleteText();
      }
    }, speed);

    return () => clearInterval(interval);
  }, [isTyping, displayText]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  const typeText = () => {
    const currentWord = words[currentWordIndex];
    if (displayText.length < currentWord.length) {
      setDisplayText((prev) => prev + currentWord.charAt(prev.length));
    } else {
      setIsTyping(false);
    }
  };

  const deleteText = () => {
    if (displayText.length > 0) {
      setDisplayText((prev) => prev.slice(0, -1));
    } else {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      setIsTyping(true);
    }
  };

  return (
    <span
      style={{
        background: "-webkit-linear-gradient(left, #00dbde, #fd4dff)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontSize: "1.1rem",
        fontWeight: "500",
      }}
    >
      {displayText}
      {showCursor && windowWidth > 705 && <span>|</span>}
    </span>
  );
}

export default TypingTextArray;
