import MoneyBackImg from "../../../../../../assets/images/moneyBack2.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useState, useEffect } from "react";
import styles from "./moneyBackSection.module.css";

function MoneyBackSection(props) {
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.guarantee_cont}>
      <div className={styles.guarantee_heading_cont}>
        <h2>Still Not Sure? We got your Back!</h2>
      </div>

      <div className={styles.guarantee_cont_sub}>
        <div className={styles.guarantee_text_cont}>
          <h2>Our Guarantee</h2>
          <span>
            Get this amazing offer today for just Rs 299, and enjoy a{" "}
            <b>14-day money-back guarantee.</b> Join today, access our deep
            hybrid analysis, and if you’re not satisfied for any reason, simply
            send us an email and we’ll refund every penny.
            <br /> – no questions asked!
          </span>

          {windowWidth <= 854 && (
            <div className={styles.guarantee_img_cont}>
              <img src={MoneyBackImg} alt="money-back-guarantee-img" />
            </div>
          )}

          <div className={styles.one_btn_cont}>
            <button
              onClick={() =>
                props.cta("FUNNEL - MoneyBack container CTA btn clicked")
              }
            >
              Get it for ₹299{" "}
              <div
                onClick={() =>
                  props.cta("FUNNEL - MoneyBack container CTA btn clicked")
                }
              >
                <MdKeyboardArrowRight color="#000" size={25} />
              </div>
            </button>
          </div>
        </div>

        {windowWidth > 854 && (
          <div className={styles.guarantee_img_cont}>
            <img src={MoneyBackImg} alt="money-back-guarantee-img" />
          </div>
        )}
      </div>
    </div>
  );
}

export default MoneyBackSection;
