import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const LineChartComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={217}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
      >
        <CartesianGrid
          strokeDasharray=""
          strokeOpacity={0.2}
          vertical={false}
        />
        <XAxis dataKey="month" />

        <Tooltip
          contentStyle={{
            backgroundColor: "#313538",
            borderColor: "#3f4345",
            borderRadius: "8px",
          }}
          labelStyle={{ color: "#9ba1a6" }}
        />

        <Line
          type="monotone"
          dataKey="risk"
          stroke="red"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="reward"
          stroke="#00ff0a"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
