import { Sidebar, SubMenu, Menu, MenuItem } from "react-pro-sidebar";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdPlayCircle } from "react-icons/io";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import { Digital } from "react-activity";
import { AuthContext } from "../../../../components/store/context/authContextProvider";
import { CallGetApiServicesWithTkn } from "../../../../utils/webServices/apiCalls";
import ButtonComponent from "../../../../components/buttonComponent/buttonComponent";
import styles from "../../../afterLoggedInPages/myCoursesPage/contents/contents.module.css";
import stylesOne from "../../../beforeLoggedInPages/analysisStatsPage/analysisStatsPage.module.css";
import "../../../afterLoggedInPages/myCoursesPage/contents/style.css";

function FreeCourseContentPage() {
  const { isValidToken, token, courseType } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("STARTER");
  const [selectedCategoryUpdate, setSelectedCategoryUpdate] =
    useState("STARTER");
  const [selectedCategoryLink, setSelectedCategoryLink] =
    useState("startContent");
  const [selectedContent, setSelectedContent] = useState(
    "Introduction to the Market - 1"
  );
  const [selectedContentToDisplay, setSelectedContentToDisplay] =
    useState(null);
  const [IsLoading, setIsLoading] = useState(false);
  const [allContent, setAllContent] = useState(null);
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [mainTopicsTechnicals, setMainTopicsTechnicals] = useState([
    {
      name: "STARTER",
      duration: `1hr:08 mins`,
      link: "startContent",
      content: [
        {
          name: "Introduction to the Market - 1",
          duration: `17:00 mins`,
        },
        {
          name: "Introduction to the Market - 2",
          duration: `16:32 mins`,
        },
        {
          name: "The untold story of the Stock Market in India",
          duration: `01:38 mins`,
        },
        {
          name: "How indian stock market works ?",
          duration: `05:55 mins`,
        },
        {
          name: "The inside market series",
          duration: `01:19 mins`,
        },
      ],
    },
    {
      name: "CORE",
      duration: `1hr:44 mins`,
      link: "coreContent",
      content: [
        {
          name: "Why do companies get listed on the stock market ?",
          duration: `10:00 mins`,
        },
        {
          name: "What is primary market & secondary market ?",
          duration: `04:11 mins`,
        },
        {
          name: "What is NSE & BSE ?",
          duration: `04:53 mins`,
        },
        {
          name: "Types of investors in stock market",
          duration: `07:01 mins`,
        },
        {
          name: "Don't be IGF",
          duration: `07:01 mins`,
        },
        {
          name: "How to analyze stocks like a pro ?",
          duration: `10:35 mins`,
        },
        {
          name: "Trading vs Investment",
          duration: `06:57 mins`,
        },
        {
          name: "What is Demat and Trading Account ?",
          duration: `06:35 mins`,
        },
        {
          name: "Mandatory tools for every stock market investor",
          duration: `05:39 mins`,
        },
      ],
    },
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function callCourseContent() {
    if (!isValidToken || !token) {
      return;
    }
    setIsLoading(true);
    CallGetApiServicesWithTkn(
      `/course/fundamental-free-course-content-web`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      (response) => {
        if (response.status === 200) {
          setAllContent(response.data);
          setSelectedContentToDisplay(response.data.startContent[0]);
          setIsLoading(false);
        }
      },
      (err) => {
        setIsLoading(false);
        toast.error("Error fetching contents");
        console.log("err getting technical allCourseContents", err);
      }
    );
  }

  useEffect(() => {
    if (isValidToken) {
      callCourseContent();
    }
  }, [isValidToken, token]);

  function categoryHandler(name, linkName) {
    setSelectedCategory(name);
    setSelectedCategoryLink(linkName);
  }

  function contentHandler(name) {
    setSelectedContent(name);
    setSelectedCategoryUpdate(selectedCategory);
    if (allContent) {
      const filteredContent = allContent[selectedCategoryLink].find(
        (content) => content.name === name
      );
      setSelectedContentToDisplay(filteredContent);
    }
  }

  return (
    <div className={styles.course_main_container}>
      {windowWidth > 900 ? (
        <>
          {isValidToken ? (
            <>
              <div className={styles.video_desc_cont}>
                <div className={styles.video_player_cont}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: selectedContentToDisplay?.link || "Nill",
                    }}
                  />
                </div>

                <div className={styles.desc_cont}>
                  <div className={styles.desc_card}>
                    <span>{selectedCategoryUpdate || "NILL"}</span>
                  </div>
                  <div className={styles.desc_heading}>
                    <span>{selectedContent || "NILL"}</span>
                  </div>
                  <div className={styles.desc_description}>
                    <span>{selectedContentToDisplay?.pointOne || "Nill"}</span>
                    <span>{selectedContentToDisplay?.pointTwo || "Nill"}</span>
                    <span>
                      {selectedContentToDisplay?.pointThree || "Nill"}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.category_cont}>
                <Sidebar
                  style={{
                    width: "100%",
                    borderColor: "#151718",
                  }}
                >
                  <Menu>
                    {mainTopicsTechnicals.map((topic) => (
                      <SubMenu
                        rootStyles={{
                          backgroundColor: "#151718",
                          color: "#fff",
                          fontSize: "0.9rem",
                        }}
                        key={topic.name}
                        label={topic.name}
                        open={selectedCategory === topic.name}
                        onOpenChange={() =>
                          categoryHandler(topic.name, topic.link)
                        }
                      >
                        {topic.content.map((subItem) => (
                          <MenuItem
                            icon={<IoMdPlayCircle size={20} color="#0c969a" />}
                            rootStyles={{
                              backgroundColor:
                                selectedContent === subItem.name
                                  ? "#515354"
                                  : "#313538",
                              color: "#fff",
                              height: "3.9rem",
                            }}
                            key={subItem.name}
                            onClick={() => contentHandler(subItem.name)}
                          >
                            <div className={styles.sub_item_cont}>
                              <span>{subItem.name}</span>
                              <span>{subItem.duration}</span>
                            </div>
                          </MenuItem>
                        ))}
                      </SubMenu>
                    ))}
                  </Menu>
                </Sidebar>
              </div>
            </>
          ) : (
            <div className={stylesOne.upgrade_btn_main_cont}>
              <div className={stylesOne.upgrade_btn_cont}>
                <span className={stylesOne.upgrade_btn_cont_text}>
                  Please register for free to view this content.
                </span>
                <ButtonComponent
                  text={"Register"}
                  handler={() => {
                    navigate("/signup");
                  }}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.video_desc_cont}>
            {isValidToken ? (
              <div className={styles.video_player_cont}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: selectedContentToDisplay?.link || "Nill",
                  }}
                />
              </div>
            ) : (
              <div
                className={stylesOne.upgrade_btn_main_cont}
                style={{ marginTop: windowWidth > 900 ? "50%" : "10%" }}
              >
                <div className={stylesOne.upgrade_btn_cont}>
                  <span className={stylesOne.upgrade_btn_cont_text}>
                    Upgrade for free to view this content.
                  </span>
                  <ButtonComponent
                    text={"Register"}
                    handler={() => {
                      navigate("/signup");
                    }}
                  />
                </div>
              </div>
            )}
            <Tabs defaultFocus={true}>
              <TabList>
                <Tab>contents</Tab>
                <Tab disabled={!isValidToken}>description</Tab>
              </TabList>

              <TabPanel>
                <div className={styles.category_cont}>
                  <Sidebar
                    style={{
                      width: "100%",
                      borderColor: "#151718",
                    }}
                  >
                    <Menu>
                      {mainTopicsTechnicals.map((topic) => (
                        <SubMenu
                          rootStyles={{
                            backgroundColor: "#151718",
                            color: "#fff",
                            fontSize: "0.9rem",
                          }}
                          key={topic.name}
                          label={topic.name}
                          open={selectedCategory === topic.name}
                          onOpenChange={() =>
                            categoryHandler(topic.name, topic.link)
                          }
                        >
                          {topic.content.map((subItem) => (
                            <MenuItem
                              icon={
                                <IoMdPlayCircle size={20} color="#0c969a" />
                              }
                              rootStyles={{
                                backgroundColor:
                                  selectedContent === subItem.name
                                    ? "#6a6d6e"
                                    : "#313538",
                                color: "#fff",
                                height: "3.9rem",
                              }}
                              key={subItem.name}
                              onClick={() => contentHandler(subItem.name)}
                            >
                              <div className={styles.sub_item_cont}>
                                <span>{subItem.name}</span>
                                <span>{subItem.duration}</span>
                              </div>
                            </MenuItem>
                          ))}
                        </SubMenu>
                      ))}
                    </Menu>
                  </Sidebar>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={styles.desc_cont}>
                  <div className={styles.desc_card}>
                    <span>{selectedCategoryUpdate || "NILL"}</span>
                  </div>
                  <div className={styles.desc_heading}>
                    <span>{selectedContent || "NILL"}</span>
                  </div>
                  <div className={styles.desc_description}>
                    <span>{selectedContentToDisplay?.pointOne || "Nill"}</span>
                    <span>{selectedContentToDisplay?.pointTwo || "Nill"}</span>
                    <span>
                      {selectedContentToDisplay?.pointThree || "Nill"}
                    </span>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
}

export default FreeCourseContentPage;
