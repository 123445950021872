import styles from "./dashboardPage.module.css";
import analysisStyles from "../../beforeLoggedInPages/analysisStatsPage/analysisStatsPage.module.css";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../components/store/context/authContextProvider";
import GaugeChart from "react-gauge-chart";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { CallGetApiServices } from "../../../utils/webServices/apiCalls";
import "react-circular-progressbar/dist/styles.css";
import ToggleSwitch from "../../../components/toggleSwitch/toggleSwitch";
import BlinkingDot from "../../../components/BlinkingDot/blinkingDot";
import Marquee from "react-fast-marquee";
import LineChartComponent from "../../../components/charts/lineChart";
import TradingViewChart from "../../../components/charts/tradingViewChart";
import TradingViewNiftyChart from "../../../components/charts/tradingViewNiftyChart";
import { Helmet } from "react-helmet";

function DashboardPage() {
  const authCtx = useContext(AuthContext);
  const [gaugeChartLastMonthValue, setGaugeChartLastMonthValue] = useState(0.4);
  const [gaugeChartFiveMonthValue, setGaugeChartFiveMonthValue] = useState(0.6);
  const [gaugeChartOverallValue, setGaugeChartOverallValue] = useState(0.85);
  const [status, setStatus] = useState(`swingAnalysisStats`);
  const [analysisData, setAnalysisData] = useState([]);
  const [viewResult, setViewResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [graphData, setGraphData] = useState([]);

  const data = [
    {
      month: "Nov",
      year: 2023,
      risk: 0,
      reward: 0,
    },
    {
      month: "Dec",
      year: 2023,
      risk: 0,
      reward: 0,
    },
    {
      month: "Jan",
      year: 2024,
      risk: 1,
      reward: 2,
    },
    {
      month: "Feb",
      year: 2024,
      risk: 1,
      reward: 2,
    },
    {
      month: "Mar",
      year: 2024,
      risk: 0,
      reward: 0,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (authCtx.toggleCheckedStatus) {
      setStatus(`freeSwingAnalysisStats`);
      setGraphData(authCtx.freeSwingAnalysisStats.reversedMonthlyTotals);
    } else {
      setStatus(`swingAnalysisStats`);
      setGraphData(authCtx.swingAnalysisStats.reversedMonthlyTotals);
    }
  }, [authCtx.toggleCheckedStatus]);

  useEffect(() => {
    if (
      authCtx.freeSwingAnalysisStats.reversedMonthlyTotals &&
      authCtx.swingAnalysisStats.reversedMonthlyTotals
    ) {
      const riskLastMonth =
        authCtx[status]?.totalRiskLastMonth > 0
          ? authCtx[status].totalRiskLastMonth
          : 10 || 10;

      const rewardLastMonth =
        authCtx[status]?.totalRewardLastMonth > 0
          ? authCtx[status].totalRewardLastMonth
          : 50 || 50;
      const percentageLastMonth =
        rewardLastMonth / (riskLastMonth + rewardLastMonth);
      setGaugeChartLastMonthValue(percentageLastMonth);

      ///////////////////////////////////////////////////////

      const riskFiveMonth =
        authCtx[status]?.totalRiskLastFiveMonth > 0
          ? authCtx[status].totalRiskLastFiveMonth
          : 10 || 10;

      const rewardFiveMonth =
        authCtx[status]?.totalRewardLastFiveMonth > 0
          ? authCtx[status].totalRewardLastFiveMonth
          : 50 || 50;
      const percentageFiveMonth =
        rewardFiveMonth / (riskFiveMonth + rewardFiveMonth);
      setGaugeChartFiveMonthValue(percentageFiveMonth);

      ///////////////////////////////////////////////////////

      const riskOverall =
        authCtx[status]?.totalRisk > 0 ? authCtx[status].totalRisk : 10 || 10;

      const rewardOverall =
        authCtx[status]?.totalReward > 0
          ? authCtx[status].totalReward
          : 50 || 50;
      const percentageOverall = rewardOverall / (riskOverall + rewardOverall);
      setGaugeChartOverallValue(percentageOverall);
    }
  }, [status, authCtx.freeSwingAnalysisStats, authCtx.swingAnalysisStats]);

  function getAllAnalysis() {
    setIsLoading(true);
    CallGetApiServices(
      `/analysis/free-swing-analysis/get-all-free-swing-analysis?page=1`,
      (response) => {
        if (response.status === 200) {
          setAnalysisData(response.data.allSwingAnalyses);
          setIsLoading(false);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err getting getallanalysis", err);
      }
    );
  }

  useEffect(() => {
    getAllAnalysis();
  }, []);

  function viewResultHandler(index) {
    setViewResult(!viewResult);
  }

  console.log(authCtx.swingAnalysisStats);

  return (
    // <div class="d-flex flex-column">
    //   <div class="">
    //     <nav class="navbar navbar-expand-lg navbar-light bg-light">
    //       <a class="navbar-brand" href="#">
    //         Navbar
    //       </a>

    //       <div class="collapse navbar-collapse" id="navbarSupportedContent">
    //         <ul class="navbar-nav mr-auto" style={{ width: "100%" }}>
    //           <li class="nav-item active">
    //             <a class="nav-link" href="#">
    //               DENTAL MARKET PLACE
    //             </a>
    //           </li>

    //           <li class="nav-item me-3 me-lg-0 " style={{ marginLeft: "auto" }}>
    //             <a class="nav-link text-black" href="#">
    //               <i class="fas fa-cog mx-1"></i> Settings
    //             </a>
    //           </li>

    //           <li class="nav-item dropdown">
    //             <a
    //               class="nav-link dropdown-toggle text-black"
    //               href="#"
    //               id="navbarDropdown"
    //               role="button"
    //               data-mdb-toggle="dropdown"
    //               aria-expanded="false"
    //             >
    //               <i class="fas fa-user mx-1"></i> Profile
    //             </a>

    //             <ul
    //               class="dropdown-menu dropdown-menu-end"
    //               aria-labelledby="navbarDropdown"
    //             >
    //               <li>
    //                 <a class="dropdown-item" href="#">
    //                   My account
    //                 </a>
    //               </li>

    //               <li>
    //                 <a class="dropdown-item" href="#">
    //                   Log out
    //                 </a>
    //               </li>
    //             </ul>
    //           </li>
    //         </ul>
    //       </div>
    //     </nav>
    //   </div>
    //   <div class="">
    //     <div
    //       id="carouselExampleCaptions"
    //       class="carousel slide"
    //       data-bs-ride="carousel"
    //     >
    //       <div class="carousel-indicators">
    //         <button
    //           type="button"
    //           data-bs-target="#carouselExampleCaptions"
    //           data-bs-slide-to="0"
    //           class="active"
    //           aria-current="true"
    //           aria-label="Slide 1"
    //         ></button>
    //         <button
    //           type="button"
    //           data-bs-target="#carouselExampleCaptions"
    //           data-bs-slide-to="1"
    //           aria-label="Slide 2"
    //         ></button>
    //       </div>
    //       <div class="carousel-inner">
    //         <div class="carousel-item active">
    //           <img
    //             src={image1}
    //             class="d-block w-100"
    //             alt="slider-image"
    //             width={200}
    //             height={350}
    //           />
    //         </div>
    //         <div class="carousel-item">
    //           <img
    //             src={image2}
    //             class="d-block w-100"
    //             alt="slider-image"
    //             width={200}
    //             height={350}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div class="p-5 bg-light">
    //     <div
    //       class="d-flex flex-row mb-5"
    //       style={{ justifyContent: "space-evenly" }}
    //     >
    //       <div class="card" style={{ width: "18rem" }}>
    //         <img src={image1} class="card-img-top" alt="..." />
    //         <div class="card-body">
    //           <p class="card-text text-center">Dental</p>
    //         </div>
    //       </div>
    //       <div class="card" style={{ width: "18rem" }}>
    //         <img src={image1} class="card-img-top" alt="..." />
    //         <div class="card-body">
    //           <p class="card-text text-center">Dental</p>
    //         </div>
    //       </div>
    //       <div class="card" style={{ width: "18rem" }}>
    //         <img src={image1} class="card-img-top" alt="..." />
    //         <div class="card-body">
    //           <p class="card-text text-center">Dental</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       class="d-flex flex-row mb-3"
    //       style={{ justifyContent: "space-evenly" }}
    //     >
    //       <div class="card" style={{ width: "18rem" }}>
    //         <img src={image1} class="card-img-top" alt="..." />
    //         <div class="card-body">
    //           <p class="card-text text-center">Dental</p>
    //         </div>
    //       </div>
    //       <div class="card" style={{ width: "18rem" }}>
    //         <img src={image1} class="card-img-top" alt="..." />
    //         <div class="card-body">
    //           <p class="card-text text-center">Dental</p>
    //         </div>
    //       </div>
    //       <div class="card" style={{ width: "18rem" }}>
    //         <img src={image1} class="card-img-top" alt="..." />
    //         <div class="card-body">
    //           <p class="card-text text-center">Dental</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <Helmet>
        <title>Dashboard - Charteey</title>
        <link rel="canonical" href="https://charteey.com/dashboard" />
        <meta
          name="description"
          content="Track live market performance, breakout stocks, and index trends on our trading dashboard. Stay ahead with expert analysis and real-time insights."
        />
      </Helmet>
      <div className={styles.dashboard_container_main}>
        <div className={styles.cards_main_cont}>
          <div className={styles.cards_heading_cont}>
            <span style={{ marginBottom: "2.5px" }}>
              {`Statistics - ${
                authCtx.toggleCheckedStatus ? "Free" : "Paid"
              } analysis`}
            </span>
            <ToggleSwitch isTextVisible={false} />
          </div>
          <div className={styles.cards_cont}>
            <div className={styles.cards}>
              <div className={styles.card_heading_cont}>
                <span className={styles.card_heading_text}>Last month</span>
                <div className={styles.card_gauge_cont}>
                  <GaugeChart
                    id="gauge-chart2"
                    arcsLength={[0.2, 0.5, 0.3]}
                    colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                    nrOfLevels={20}
                    percent={gaugeChartLastMonthValue}
                    // arcPadding={0.08}
                    hideText={true}
                  />
                </div>
              </div>

              <div className={styles.cards_visual_cont}>
                <div className={styles.donut_cont}>
                  <CircularProgressbar
                    value={gaugeChartLastMonthValue * 100}
                    text={`${(gaugeChartLastMonthValue * 100).toFixed(2)}%`}
                    styles={buildStyles({
                      textColor: "#999",
                      pathColor: "#284d5f",
                      trailColor: "#0c969a",
                    })}
                  />
                </div>

                <div className={styles.line_main_cont}>
                  <div className={styles.line_cont}>
                    <div className={styles.line_out}>
                      <div
                        className={styles.line_in_one}
                        style={{
                          width: `${
                            authCtx[status]?.totalRiskLastMonth === 1
                              ? 20
                              : authCtx[status]?.totalRiskLastMonth * 3 > 20
                              ? 20
                              : authCtx[status]?.totalRiskLastMonth * 10 > 40
                              ? 40
                              : authCtx[status]?.totalRiskLastMonth * 10
                          }%`,
                        }}
                      >
                        <span className={styles.line_in_one_text}>Risk</span>
                        <span className={styles.line_in_one_num}>
                          {authCtx[status]?.totalRiskLastMonth}
                        </span>
                      </div>

                      <div
                        className={styles.line_in_two}
                        style={{
                          width: `${
                            authCtx[status]?.totalRewardLastMonth === 2
                              ? 40
                              : authCtx[status]?.totalRewardLastMonth * 3 > 60
                              ? 40
                              : authCtx[status]?.totalRewardLastMonth * 10 > 80
                              ? 80
                              : authCtx[status]?.totalRewardLastMonth * 10
                          }%`,
                        }}
                      >
                        <span className={styles.line_in_two_text}>Reward</span>
                        <span className={styles.line_in_two_num}>
                          {authCtx[status]?.totalRewardLastMonth}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.cards}>
              <div className={styles.card_heading_cont}>
                <span className={styles.card_heading_text}>
                  Last five month
                </span>
                <div className={styles.card_gauge_cont}>
                  <GaugeChart
                    id="gauge-chart2"
                    arcsLength={[0.2, 0.5, 0.3]}
                    colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                    nrOfLevels={20}
                    percent={gaugeChartFiveMonthValue}
                    // arcPadding={0.08}
                    hideText={true}
                  />
                </div>
              </div>

              <div className={styles.cards_visual_cont}>
                <div className={styles.donut_cont}>
                  <CircularProgressbar
                    value={gaugeChartFiveMonthValue * 100}
                    text={`${(gaugeChartFiveMonthValue * 100).toFixed(2)}%`}
                    styles={buildStyles({
                      textColor: "#999",
                      pathColor: "#284d5f",
                      trailColor: "#0c969a",
                    })}
                  />
                </div>

                <div className={styles.line_main_cont}>
                  <div className={styles.line_cont}>
                    <div className={styles.line_out}>
                      <div
                        className={styles.line_in_one}
                        style={{
                          width: `${
                            authCtx[status]?.totalRiskLastFiveMonth * 3 > 20
                              ? 20
                              : authCtx[status]?.totalRiskLastFiveMonth * 10 >
                                40
                              ? 40
                              : authCtx[status]?.totalRiskLastFiveMonth * 10
                          }%`,
                        }}
                      >
                        <span className={styles.line_in_one_text}>Risk</span>
                        <span className={styles.line_in_one_num}>
                          {authCtx[status]?.totalRiskLastFiveMonth}
                        </span>
                      </div>

                      <div
                        className={styles.line_in_two}
                        style={{
                          width: `${
                            authCtx[status]?.totalRewardLastiveMonth * 3 > 60
                              ? 40
                              : authCtx[status]?.totalRewardLastFiveMonth * 10 >
                                80
                              ? 80
                              : authCtx[status]?.totalRewardLastFiveMonth * 10
                          }%`,
                        }}
                      >
                        <span className={styles.line_in_two_text}>Reward</span>
                        <span className={styles.line_in_two_num}>
                          {authCtx[status]?.totalRewardLastFiveMonth}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.cards}>
              <div className={styles.card_heading_cont}>
                <span className={styles.card_heading_text}>Overall</span>
                <div className={styles.card_gauge_cont}>
                  <GaugeChart
                    id="gauge-chart2"
                    arcsLength={[0.2, 0.5, 0.3]}
                    colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                    nrOfLevels={20}
                    percent={gaugeChartOverallValue}
                    // arcPadding={0.08}
                    hideText={true}
                  />
                </div>
              </div>

              <div className={styles.cards_visual_cont}>
                <div className={styles.donut_cont}>
                  <CircularProgressbar
                    value={gaugeChartOverallValue * 100}
                    text={`${(gaugeChartOverallValue * 100).toFixed(2)}%`}
                    styles={buildStyles({
                      textColor: "#999",
                      pathColor: "#284d5f",
                      trailColor: "#0c969a",
                    })}
                  />
                </div>

                <div className={styles.line_main_cont}>
                  <div className={styles.line_cont}>
                    <div className={styles.line_out}>
                      <div
                        className={styles.line_in_one}
                        style={{
                          width: `${
                            authCtx[status]?.totalRisk * 3 > 20
                              ? 20
                              : authCtx[status]?.totalRisk * 10 > 40
                              ? 40
                              : authCtx[status]?.totalRisk * 10
                          }%`,
                        }}
                      >
                        <span className={styles.line_in_one_text}>Risk</span>
                        <span className={styles.line_in_one_num}>
                          {authCtx[status]?.totalRisk}
                        </span>
                      </div>

                      <div
                        className={styles.line_in_two}
                        style={{
                          width: `${
                            authCtx[status]?.totalReward * 3 > 60
                              ? 40
                              : authCtx[status]?.totalReward * 10 > 80
                              ? 80
                              : authCtx[status]?.totalReward * 10
                          }%`,
                        }}
                      >
                        <span className={styles.line_in_two_text}>Reward</span>
                        <span className={styles.line_in_two_num}>
                          {authCtx[status]?.totalReward}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.description_cont}>
          <div className={styles.consider_stocks_cont}>
            <div className={styles.consider_stocks_cont_sub}>
              <div className={styles.example_cont}>
                <div className={styles.example_cont_img}></div>

                <div className={styles.text_rr_cont}>
                  <span className={styles.example_text}>
                    Consider risking 1%(1000) of your 1 lakh capital on our
                    analysis...
                  </span>
                  <div className={styles.rr_cont}>
                    <div className={styles.rr}>
                      <span>
                        Risk is{" "}
                        <span style={{ color: "#b22222" }}>
                          {authCtx[status]?.totalRiskLastMonth * 1000}
                        </span>
                      </span>
                    </div>
                    <div className={styles.rr}>
                      <span>
                        Reward is{" "}
                        <span style={{ color: "#016646" }}>
                          {authCtx[status]?.totalRewardLastMonth * 1000}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.stocks_to_watch}>
                <span className={styles.free_analysis_heading_text}>
                  Stocks to watch
                </span>
                <div className={styles.indicator_cont}>
                  <div className={styles.indicator_cont_sub}>
                    <div className={styles.circle_outer}>
                      <div
                        className={styles.circle_inner}
                        style={{ backgroundColor: "#00ff0a" }}
                      ></div>
                    </div>
                    <span className={styles.indicator_text}>Breakout</span>
                  </div>

                  <div className={styles.indicator_cont_sub}>
                    <div className={styles.circle_outer}>
                      <div
                        className={styles.circle_inner}
                        style={{ backgroundColor: "#FF5F1F" }}
                      ></div>
                    </div>
                    <span className={styles.indicator_text}>Trailing</span>
                  </div>
                </div>

                <div className={styles.marquee_cont}>
                  <Marquee pauseOnHover={true}>
                    <div className={styles.marquee_cont_sub}>
                      {authCtx.allBreakoutAnalyses.map((item, index) => {
                        return (
                          <div
                            key={item?._id || index}
                            className={styles.marquee_card}
                          >
                            <BlinkingDot color={item?.result?.breakout} />
                            <span>{item?.analysis?.stockName}</span>
                          </div>
                        );
                      })}
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>

            <div className={styles.trading_ticker_cont}>
              <TradingViewChart />
            </div>
          </div>

          {windowWidth > 1060 && (
            <div className={styles.free_analysis_cont}>
              <span className={styles.free_analysis_heading_text}>
                Weekly free analysis
              </span>

              <div className={analysisStyles.analysis}>
                <div className={analysisStyles.analysis_name_area}>
                  <div className={analysisStyles.name_card}>
                    <span>{analysisData[0]?.analysis?.stockName}</span>
                  </div>
                  <div className={analysisStyles.name_card}>
                    <span>{analysisData[0]?.analysis?.pattern}</span>
                  </div>
                </div>
                <div className={analysisStyles.analysis_link_area}>
                  <a href={analysisData[0]?.analysis.analysisLink}>
                    {analysisData[0]?.analysis.analysisLink}
                  </a>
                  <img
                    src={analysisData[0]?.analysis.analysisLink}
                    alt="tradingview.com"
                  />
                </div>
                <div className={analysisStyles.analysis_result_area}>
                  <div
                    onClick={() => viewResultHandler()}
                    style={{ cursor: "pointer" }}
                  >
                    <span>view result</span>
                  </div>
                </div>

                {viewResult &&
                  (analysisData[0]?.result.resultLink &&
                  analysisData[0]?.result.resultLink !== "none" ? (
                    <>
                      <div className={analysisStyles.analysis_result}>
                        <span>
                          {analysisData[0]?.result?.reward === 0
                            ? "stoploss hit"
                            : `${analysisData[0]?.result.risk}:${analysisData[0]?.result.reward} RR`}
                        </span>
                        <span>
                          {analysisData[0]?.result?.reward === 0
                            ? -analysisData[0]?.result?.percentage
                            : analysisData[0]?.result?.percentage}
                          %
                        </span>
                      </div>
                      <div className={analysisStyles.analysis_link_area}>
                        <a href={analysisData[0]?.result.resultLink}>
                          {analysisData[0]?.result.resultLink}
                        </a>
                        <img
                          src={analysisData[0]?.result.resultLink}
                          alt="tradingview.com"
                        />
                      </div>
                    </>
                  ) : (
                    <span className={analysisStyles.result_text}>
                      {analysisData[0]?.result.resultLink == "none"
                        ? "no result"
                        : "not yet updated..."}
                    </span>
                  ))}
              </div>
            </div>
          )}
          {windowWidth <= 1060 && (
            <div className={styles.free_nifty_cont}>
              <div className={styles.free_analysis_cont}>
                <span className={styles.free_analysis_heading_text}>
                  Weekly free analysis
                </span>

                <div className={analysisStyles.analysis}>
                  <div className={analysisStyles.analysis_name_area}>
                    <div className={analysisStyles.name_card}>
                      <span>{analysisData[0]?.analysis?.stockName}</span>
                    </div>
                    <div className={analysisStyles.name_card}>
                      <span>{analysisData[0]?.analysis?.pattern}</span>
                    </div>
                  </div>
                  <div className={analysisStyles.analysis_link_area}>
                    <a href={analysisData[0]?.analysis.analysisLink}>
                      {analysisData[0]?.analysis.analysisLink}
                    </a>
                    <img
                      src={analysisData[0]?.analysis.analysisLink}
                      alt="tradingview.com"
                    />
                  </div>
                  <div className={analysisStyles.analysis_result_area}>
                    <div
                      onClick={() => viewResultHandler()}
                      style={{ cursor: "pointer" }}
                    >
                      <span>view result</span>
                    </div>
                  </div>

                  {viewResult &&
                    (analysisData[0]?.result.resultLink &&
                    analysisData[0]?.result.resultLink !== "none" ? (
                      <>
                        <div className={analysisStyles.analysis_result}>
                          <span>
                            {analysisData[0]?.result?.reward === 0
                              ? "stoploss hit"
                              : `${analysisData[0]?.result.risk}:${analysisData[0]?.result.reward} RR`}
                          </span>
                          <span>
                            {analysisData[0]?.result?.reward === 0
                              ? -analysisData[0]?.result?.percentage
                              : analysisData[0]?.result?.percentage}
                            %
                          </span>
                        </div>
                        <div className={analysisStyles.analysis_link_area}>
                          <a href={analysisData[0]?.result.resultLink}>
                            {analysisData[0]?.result.resultLink}
                          </a>
                          <img
                            src={analysisData[0]?.result.resultLink}
                            alt="tradingview.com"
                          />
                        </div>
                      </>
                    ) : (
                      <span className={analysisStyles.result_text}>
                        {analysisData[0]?.result.resultLink == "none"
                          ? "no result"
                          : "not yet updated..."}
                      </span>
                    ))}
                </div>
              </div>

              <div className={styles.status}>
                <TradingViewNiftyChart />
              </div>
            </div>
          )}
        </div>

        <div className={styles.chart_status_cont}>
          <div className={styles.chart}>
            <div className={styles.chart_heading_cont}>
              <span className={styles.free_analysis_heading_text}>
                Month wise stats
              </span>
              <div className={styles.chart_name_card}>
                <span>{authCtx.toggleCheckedStatus ? "Free" : "Paid"}</span>
              </div>
            </div>

            <LineChartComponent data={graphData} />
          </div>

          {windowWidth > 1060 && (
            <div className={styles.status}>
              <TradingViewNiftyChart />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
