import AnalysisContainer from "./components/analysisContainer";
import VisualContainer from "./components/visualContainer";
import styles from "./analysisStatsPage.module.css";
import { Helmet } from "react-helmet";

function AnalysisStatsPage() {
  return (
    <section className={styles.analysis_page_container}>
      <Helmet>
        <title>Analysis statistics - charteey</title>
        <link rel="canonical" href="https://charteey.com/analysis-statistics" />
        <meta
          name="description"
          content="Unlock the power of trading analysis with our community. Get expert insights, live updates, and essential fundamentals. Join us for real-time market intelligence and sharpen your trading edge today."
        />
      </Helmet>
      <VisualContainer />
      <AnalysisContainer />
    </section>
  );
}
export default AnalysisStatsPage;
