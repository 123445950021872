import styles from "../privacyPolicy/privacyPolicyPage.module.css";
import stylesOne from "../termsOfService/termsOfservice.module.css";

function RefundPolicyPage() {
  return (
    <section className={styles.privacy_page_container}>
      <div className={styles.top_image_container}>
        <span>Refund Policy</span>
        <span>
          Charteey values customer satisfaction. Our refund policy outlines the
          terms and conditions for refund requests. Please review the policy
          carefully.
        </span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span></span>
          <span>
            Our refund policy governs the procedure for requesting a refund for
            purchases made on Charteey, including all content, functionality,
            and services offered on or through our website (www.charteey.com).
            By making a purchase, you agree to be bound by this refund policy.
            If you do not agree to all the terms and conditions of this policy,
            then you may not be eligible for a refund.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Eligibility for Refund</span>
          <span>
            You may be eligible for a refund under the following circumstances:
            <ul>
              <li>The purchase was made within the last 10 days.</li>
              <li>
                You have not accessed or utilized the purchased content or
                services.
              </li>
              <li>
                You have encountered technical issues preventing you from
                accessing the content or services.
              </li>
              <li>
                If your refund request is approved, we will process it within
                5-7 working days.
              </li>
            </ul>
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Refund Procedure</span>
          <span>
            To request a refund, please contact us at support@charteey.com
            within 10 days of your purchase date. Include your order details and
            the reason for your refund request. We will review your request and
            initiate the refund process if you meet the eligibility criteria.
            Refunds will be issued to the original payment method used for the
            purchase.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Exceptions</span>
          <span>
            Please note that certain products or services may not be eligible
            for refunds, such as:
            <ul>
              <li>
                Downloadable digital products once they have been accessed or
                downloaded.
              </li>
              <li>Services that have been fully rendered.</li>
            </ul>
            We reserve the right to refuse refunds for products or services that
            do not meet the eligibility criteria or fall under the exceptions
            mentioned above.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Contact Us</span>
          <span>
            If you have any questions or concerns about our refund policy,
            please contact us at support@charteey.com.
          </span>
        </div>

        <div className={styles.head_date_container}>
          <span>Last updated :</span>
          <span> March 12, 2024</span>
        </div>
      </div>
    </section>
  );
}

export default RefundPolicyPage;
