import Logo from "../../../../../assets/images/logo.svg";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaTelegramPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./footerContainer.module.css";

function FooterContainer() {
  const navigate = useNavigate();

  const handleFacebookClick = () => {
    window.location.href =
      "https://www.facebook.com/profile.php?id=61557963036016";
  };

  const handleInstagramClick = () => {
    window.location.href =
      "https://www.instagram.com/charteey_?igsh=MTc0anJxMmlrazYzdQ==";
  };

  const handleLinkedinClick = () => {
    window.location.href = "https://www.linkedin.com/in/charteey-8a624a303";
  };

  const handleTelegramClick = () => {
    window.location.href = "https://t.me/+I28mCKyYb0VmMjA1";
  };

  return (
    <section className={styles.footer_container}>
      <div className={styles.footer_container_sub}>
        <div className={styles.footer}>
          <div className={styles.footer_top}>
            <div className={styles.footer_top_sub1}>
              <div className={styles.name_address}>
                <div className={styles.logo_cont}>
                  <img src={Logo} />
                  <h2>Charteey</h2>
                </div>

                <div className={styles.address_cont}>
                  <p>A Synerixs product.</p>
                </div>

                <div className={styles.google_play}>
                  <a href="https://play.google.com/store/apps/details?id=com.ram37.TradersSpotRN&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </div>
              </div>

              <div className={styles.legals}>
                <span>Legals</span>
                <p onClick={() => navigate("/terms-of-service")}>
                  Terms of service
                </p>
                <p onClick={() => navigate("/privacy-policy")}>
                  Privacy policy
                </p>
                <p onClick={() => navigate("/refund-policy")}>Refund policy</p>
                <p onClick={() => navigate("/faq")}>FAQ</p>
              </div>

              <div className={styles.legals}>
                <span>Company</span>
                <p onClick={() => navigate("/")}>Home</p>
                <p onClick={() => navigate("/about-us")}>About us</p>
              </div>

              <div className={styles.social_media}>
                <div className={styles.icons}>
                  <div className={styles.icon} onClick={handleLinkedinClick}>
                    <FaLinkedinIn size={18} color="#fff" />
                  </div>
                  <div className={styles.icon} onClick={handleFacebookClick}>
                    <FaFacebookF size={18} color="#fff" />
                  </div>
                  <div className={styles.icon} onClick={handleInstagramClick}>
                    <GrInstagram size={18} color="#fff" />
                  </div>
                  <div className={styles.icon} onClick={handleTelegramClick}>
                    <FaTelegramPlane size={18} color="#fff" />
                  </div>
                </div>

                <div className={styles.mail_mbl_cont}>
                  <div className={styles.mail_mbl}>
                    <MdEmail size={18} color="#fff" />
                    <a href="mailto:support@charteey.com" target="_blank">
                      support@charteey.com
                    </a>
                  </div>

                  <div className={styles.mail_mbl}>
                    <FaPhoneAlt size={16} color="#fff" />
                    <a href="tel:+918667882137">+91 8667882137</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer_bottom}>
            <p
              style={{ color: "#0c969a", fontWeight: "500", cursor: "pointer" }}
            >
              Synerixs.{" "}
            </p>
            <p>&copy; 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FooterContainer;
