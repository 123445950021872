import React, { useState, useEffect, useRef } from "react";
import styles from "./blinkingDot.module.css";

const BlinkingDot = (props) => {
  return (
    <div
      className={styles.dot}
      style={{
        width: props.value || "6px",
        height: props.value || "6px",
        borderRadius: "50px",
        backgroundColor:
          props.color === "green"
            ? "#00ff0a"
            : props.color === "orange"
            ? "#FF5F1F"
            : props.color === "blue"
            ? "#003ff0"
            : "none",
      }}
    />
  );
};

export default BlinkingDot;
