import ButtonComponent from "../../../../../components/buttonComponent/buttonComponent";
import { useNavigate } from "react-router-dom";
import styles from "./joinCommunityContainer.module.css";

function JoinCommunityContainer() {
  const navigate = useNavigate();

  return (
    <section className={styles.join_community_container}>
      <div className={styles.join_community_container_sub}>
        <div className={styles.join_community_text_btn}>
          <h2>Level up your trading game with our community.</h2>
          <ButtonComponent
            text={"Signup"}
            style={styles.btn}
            handler={() => navigate("/signup")}
          />
        </div>
      </div>
    </section>
  );
}

export default JoinCommunityContainer;
