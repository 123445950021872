import React from "react";
import RailA from "../../../../../assets/analysis/railA.png";
import RailB from "../../../../../assets/analysis/railB.png";
import ChennA from "../../../../../assets/analysis/chennA.png";
import ChennB from "../../../../../assets/analysis/chennB.png";
import ElgiA from "../../../../../assets/analysis/elgiA.png";
import ElgiB from "../../../../../assets/analysis/elgiB.png";
import VakraA from "../../../../../assets/analysis/vakragA.png";
import VakraB from "../../../../../assets/analysis/vakragB.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./analysisCarousel.module.css";

const AnalysisCarousel = () => {
  return (
    <div className={styles.carousel_cont}>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        swipeable={true}
        showStatus={false}
        interval={3000}
      >
        <div className={styles.carousel}>
          <div className={styles.carousel_pic_cont}>
            <div className={styles.carousel_pic}>
              <img src={RailB} alt="rail-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>BEFORE</span>
              </div>
            </div>
            <div className={styles.carousel_pic}>
              <img src={RailA} alt="rail-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>AFTER</span>
              </div>
            </div>
          </div>

          <div className={styles.carousel_details_cont}>
            <div className={styles.carousel_details_cont_sub}>
              <div className={styles.detail_cont}>
                <h2>RVNL</h2>
                <span>NSE Stock Name</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>274.30</h2>
                <span>Buy Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>345.85</h2>
                <span>Sell Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>12 Days</h2>
                <span>Duration</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>26%</h2>
                <span>Profit</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.carousel}>
          <div className={styles.carousel_pic_cont}>
            <div className={styles.carousel_pic}>
              <img src={ChennB} alt="chennpetro-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>BEFORE</span>
              </div>
            </div>
            <div className={styles.carousel_pic}>
              <img src={ChennA} alt="chennpetro-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>AFTER</span>
              </div>
            </div>
          </div>

          <div className={styles.carousel_details_cont}>
            <div className={styles.carousel_details_cont_sub}>
              <div className={styles.detail_cont}>
                <h2>CHENNP...</h2>
                <span>NSE Stock Name</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>803.80</h2>
                <span>Buy Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>952.70</h2>
                <span>Sell Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>6 Days</h2>
                <span>Duration</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>18%</h2>
                <span>Profit</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.carousel}>
          <div className={styles.carousel_pic_cont}>
            <div className={styles.carousel_pic}>
              <img src={ElgiB} alt="elgiequip-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>BEFORE</span>
              </div>
            </div>
            <div className={styles.carousel_pic}>
              <img src={ElgiA} alt="elgiequip-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>AFTER</span>
              </div>
            </div>
          </div>

          <div className={styles.carousel_details_cont}>
            <div className={styles.carousel_details_cont_sub}>
              <div className={styles.detail_cont}>
                <h2>ELGIEQ...</h2>
                <span>NSE Stock Name</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>550.35</h2>
                <span>Buy Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>659.40</h2>
                <span>Sell Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>19 Days</h2>
                <span>Duration</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>19%</h2>
                <span>Profit</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.carousel}>
          <div className={styles.carousel_pic_cont}>
            <div className={styles.carousel_pic}>
              <img src={VakraB} alt="vakrangee-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>BEFORE</span>
              </div>
            </div>
            <div className={styles.carousel_pic}>
              <img src={VakraA} alt="vakrangee-stock-img" />
              <div className={styles.carousel_pic_btn}>
                <span>AFTER</span>
              </div>
            </div>
          </div>

          <div className={styles.carousel_details_cont}>
            <div className={styles.carousel_details_cont_sub}>
              <div className={styles.detail_cont}>
                <h2>VAKRAN...</h2>
                <span>NSE Stock Name</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>19.30</h2>
                <span>Buy Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>22.50</h2>
                <span>Sell Price</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>4 Days</h2>
                <span>Duration</span>
              </div>

              <div className={styles.detail_cont}>
                <h2>16%</h2>
                <span>Profit</span>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default AnalysisCarousel;
