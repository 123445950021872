import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "./pricingPage.module.css";
import { event } from "../../../components/commonFunction/commonFunction";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../components/store/context/authContextProvider";
import { Helmet } from "react-helmet";

function PricingPage() {
  const navigate = useNavigate();
  const {
    setUserSelectedCourseFromPricingPage,
    setUserSelectedCourse,
    courseType,
    paid,
    paymentStatus,
    isValidToken,
    userEmail,
    ipAddress,
  } = useContext(AuthContext);

  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function courseDetailBtnHandler(course) {
    if (isValidToken) {
      event(
        "BtnClick",
        `webpage-pricingPage getOffer btn clicked by registered user ${userEmail}`,
        "none"
      );
    } else {
      event("BtnClick", "webpage-pricingPage getOffer btn clicked", ipAddress);
    }
    setUserSelectedCourseFromPricingPage(course);
    setUserSelectedCourse(course);
    navigate("/plan-details");
  }

  console.log("ps frm plan", paymentStatus);

  return (
    <div className={styles.pricing_container_main}>
      <Helmet>
        <title>Pricing - charteey</title>
        <link rel="canonical" href="https://charteey.com/analysis-statistics" />
        <meta
          name="description"
          content="Discover premium stock trading courses tailored to your needs. Explore our pricing options and take your trading skills to the next level. Enroll now!"
        />
      </Helmet>
      <span className={styles.pricing_text}>Our plan</span>
      {windowWidth >= 1147 ? (
        <div className={styles.pricing_container_sub}>
          {/* <motion.div
          whileHover={{
            scale: 1.05,
            boxShadow: "0 0 8px 0.5px #313538",
          }}
          className={styles.pricing_card}
        >
          <div className={styles.card_img_cont}>
            <div className={styles.card_img_cont_sub_one}></div>
            <div className={styles.pricing_card_float}>
              <div className={styles.pricing_card_float_sub}>
                <span>BASIC</span>
              </div>
            </div>
            <span>₹ 4,999</span>
          </div>
          <div className={styles.pricing_card_text_cont}>
            <span>PRICE ACTION - THE ONE COMPLETE COURSE</span>
          </div>

          <div className={styles.pricing_card_features_text_cont}>
            <span>Access to 14 hours of technical course content</span>
            <span>Access to weekly free analysis stats</span>
            <span>1 month unlimited course access</span>
            <span>Any time doubt clearence</span>
            <span
              style={{
                textDecoration: "underline",
                color: "#0c969a",
                cursor: "pointer",
              }}
              onClick={() => {
                if (courseType === "basic" && paid) {
                  return;
                } else {
                  courseDetailBtnHandler("basic");
                }
              }}
            >
              view more
            </span>
          </div>

          <div className={styles.btn_cont}>
            <ButtonComponent
              text={
                courseType === "basic" && paid
                  ? "Your current course"
                  : "Get this course"
              }
              disabled={courseType === "basic" && paid}
              handler={() => courseDetailBtnHandler("basic")}
            />
          </div>
        </motion.div>

        <motion.div
          whileHover={{
            scale: 1.05,
            boxShadow: "0 0 8px 0.5px #313538",
          }}
          className={styles.pricing_card}
        >
          <div className={styles.card_img_cont}>
            <div className={styles.card_img_cont_sub_two}></div>
            <div className={styles.pricing_card_float}>
              <div className={styles.pricing_card_float_sub}>
                <span>STANDARD</span>
              </div>
            </div>
            <span>₹ 7,999</span>
          </div>
          <div className={styles.pricing_card_text_cont}>
            <span>
              DOMINATE MARKETS : MASTER PRICE ACTION WITH STOCK ANALYSIS
            </span>
          </div>

          <div className={styles.pricing_card_features_text_cont}>
            <span>Access to 14 hours of technical course content</span>
            <span>Access to potential stock analysis - 6/week</span>
            <span>3 months unlimited course access</span>
            <span>Any time doubt clearence</span>
            <span
              style={{
                textDecoration: "underline",
                color: "#0c969a",
                cursor: "pointer",
              }}
              onClick={() => {
                if (courseType === "standard" && paid) {
                  return;
                } else {
                  courseDetailBtnHandler("standard");
                }
              }}
            >
              view more
            </span>
          </div>

          <div className={styles.btn_cont}>
            <ButtonComponent
              text={
                courseType === "standard" && paid
                  ? "Your current course"
                  : "Get this course"
              }
              disabled={courseType === "standard" && paid}
              handler={() => courseDetailBtnHandler("standard")}
            />
          </div>
        </motion.div> */}

          <div className={styles.pricing_text_cont}>
            <h1>No basic plan...</h1>
            <h1>No Standard plan...</h1>
            <span>
              All you need is this one "PRO" plan to develop your fundamental
              and technical stock analysis skill.
            </span>
            <span>
              For those seeking in-depth stock insights, our 'Analysis' service
              offers tailored strategies for informed trading decisions.
            </span>
          </div>

          <motion.div
            whileHover={{
              scale: 1.05,
              boxShadow: "0 0 8px 0.5px #313538",
            }}
            className={styles.pricing_card}
          >
            <div className={styles.card_img_cont}>
              <div className={styles.card_img_cont_sub_one}></div>
              <div className={styles.pricing_card_float}>
                <div className={styles.pricing_card_float_sub}>
                  <span>ANALYSIS</span>
                </div>
              </div>
              <span>₹ 599</span>
            </div>
            <div className={styles.pricing_card_text_cont}>
              <span>STRATEGIC INSIGHTS - HYBRID ANALYSIS PLAN</span>
            </div>

            <div className={styles.pricing_card_features_text_cont}>
              <span>Access to 6+ potential analysis per week</span>
              <span>Get timely high-impact stock analysis.</span>
              <span>Access to all free analysis</span>
              <span>1 year unlimited access</span>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0c969a",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    (courseType === "analysis" && paid) ||
                    (courseType === "pro" && paid)
                  ) {
                    return;
                  } else {
                    courseDetailBtnHandler("analysis");
                  }
                }}
              >
                view more
              </span>
            </div>

            <div className={styles.btn_cont}>
              <ButtonComponent
                text={
                  courseType === "analysis" && paid
                    ? "You purchased this"
                    : courseType === "pro" && paid
                    ? "You already have access"
                    : "Get Offer"
                }
                disabled={
                  (courseType === "analysis" && paid) ||
                  (courseType === "pro" && paid)
                }
                handler={() => courseDetailBtnHandler("analysis")}
              />
            </div>
          </motion.div>

          <motion.div
            whileHover={{
              scale: 1.05,
              boxShadow: "0 0 8px 0.5px #313538",
            }}
            className={styles.pricing_card}
          >
            <div className={styles.card_img_cont}>
              <div className={styles.card_img_cont_sub_three}></div>
              <div className={styles.pricing_card_float}>
                <div className={styles.pricing_card_float_sub}>
                  <span>PRO</span>
                </div>
              </div>
              <span>₹ 4,999</span>
            </div>
            <div className={styles.pricing_card_text_cont}>
              <span>
                SYNERGIZING PRICE ACTION WITH FUNDAMENTALS FOR MARKET MASTERY
              </span>
            </div>

            <div className={styles.pricing_card_features_text_cont}>
              <span>Access to 3 hours of fundamental course content</span>
              <span>Access to 14 hours of technical course content</span>
              <span>1 year unlimited access to everything</span>
              <span>1 - 1 doubt clearence</span>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0c969a",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   if (courseType === "pro" && paid) {
                //     return;
                //   } else {
                //     courseDetailBtnHandler("pro");
                //   }
                // }}
              >
                view more
              </span>
            </div>

            <div className={styles.btn_cont}>
              <ButtonComponent
                // text={
                //   courseType === "pro" && paid
                //     ? "Your current course"
                //     : "Get Offer"
                // }
                text={"Sold Out"}
                // disabled={courseType === "pro" && paid}
                disabled={true}
                handler={() => courseDetailBtnHandler("pro")}
              />
            </div>
          </motion.div>
        </div>
      ) : (
        <div className={styles.pricing_container_sub}>
          {/* <motion.div
        whileHover={{
          scale: 1.05,
          boxShadow: "0 0 8px 0.5px #313538",
        }}
        className={styles.pricing_card}
      >
        <div className={styles.card_img_cont}>
          <div className={styles.card_img_cont_sub_one}></div>
          <div className={styles.pricing_card_float}>
            <div className={styles.pricing_card_float_sub}>
              <span>BASIC</span>
            </div>
          </div>
          <span>₹ 4,999</span>
        </div>
        <div className={styles.pricing_card_text_cont}>
          <span>PRICE ACTION - THE ONE COMPLETE COURSE</span>
        </div>

        <div className={styles.pricing_card_features_text_cont}>
          <span>Access to 14 hours of technical course content</span>
          <span>Access to weekly free analysis stats</span>
          <span>1 month unlimited course access</span>
          <span>Any time doubt clearence</span>
          <span
            style={{
              textDecoration: "underline",
              color: "#0c969a",
              cursor: "pointer",
            }}
            onClick={() => {
              if (courseType === "basic" && paid) {
                return;
              } else {
                courseDetailBtnHandler("basic");
              }
            }}
          >
            view more
          </span>
        </div>

        <div className={styles.btn_cont}>
          <ButtonComponent
            text={
              courseType === "basic" && paid
                ? "Your current course"
                : "Get this course"
            }
            disabled={courseType === "basic" && paid}
            handler={() => courseDetailBtnHandler("basic")}
          />
        </div>
      </motion.div>

      <motion.div
        whileHover={{
          scale: 1.05,
          boxShadow: "0 0 8px 0.5px #313538",
        }}
        className={styles.pricing_card}
      >
        <div className={styles.card_img_cont}>
          <div className={styles.card_img_cont_sub_two}></div>
          <div className={styles.pricing_card_float}>
            <div className={styles.pricing_card_float_sub}>
              <span>STANDARD</span>
            </div>
          </div>
          <span>₹ 7,999</span>
        </div>
        <div className={styles.pricing_card_text_cont}>
          <span>
            DOMINATE MARKETS : MASTER PRICE ACTION WITH STOCK ANALYSIS
          </span>
        </div>

        <div className={styles.pricing_card_features_text_cont}>
          <span>Access to 14 hours of technical course content</span>
          <span>Access to potential stock analysis - 6/week</span>
          <span>3 months unlimited course access</span>
          <span>Any time doubt clearence</span>
          <span
            style={{
              textDecoration: "underline",
              color: "#0c969a",
              cursor: "pointer",
            }}
            onClick={() => {
              if (courseType === "standard" && paid) {
                return;
              } else {
                courseDetailBtnHandler("standard");
              }
            }}
          >
            view more
          </span>
        </div>

        <div className={styles.btn_cont}>
          <ButtonComponent
            text={
              courseType === "standard" && paid
                ? "Your current course"
                : "Get this course"
            }
            disabled={courseType === "standard" && paid}
            handler={() => courseDetailBtnHandler("standard")}
          />
        </div>
      </motion.div> */}

          <div className={styles.pricing_text_cont}>
            <h1>No basic plan...</h1>
            <h1>No Standard plan...</h1>
            <span>
              All you need is this one "PRO" plan to develop your fundamental
              and technical stock analysis skill.
            </span>
            <span>
              For those seeking in-depth stock insights, our 'Analysis' service
              offers tailored strategies for informed trading decisions.
            </span>
          </div>

          <motion.div
            whileHover={{
              scale: 1.05,
              boxShadow: "0 0 8px 0.5px #313538",
            }}
            className={styles.pricing_card}
          >
            <div className={styles.card_img_cont}>
              <div className={styles.card_img_cont_sub_three}></div>
              <div className={styles.pricing_card_float}>
                <div className={styles.pricing_card_float_sub}>
                  <span>PRO</span>
                </div>
              </div>
              <span>₹ 4,999</span>
            </div>
            <div className={styles.pricing_card_text_cont}>
              <span>
                SYNERGIZING PRICE ACTION WITH FUNDAMENTALS FOR MARKET MASTERY
              </span>
            </div>

            <div className={styles.pricing_card_features_text_cont}>
              <span>Access to 3 hours of fundamental course content</span>
              <span>Access to 14 hours of technical course content</span>
              <span>1 year unlimited access to everything</span>
              <span>1 - 1 doubt clearence</span>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0c969a",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   if (courseType === "pro" && paid) {
                //     return;
                //   } else {
                //     courseDetailBtnHandler("pro");
                //   }
                // }}
              >
                view more
              </span>
            </div>

            <div className={styles.btn_cont}>
              <ButtonComponent
                // text={
                //   courseType === "pro" && paid
                //     ? "Your current course"
                //     : "Get Offer"
                // }
                text={"Sold Out"}
                // disabled={courseType === "pro" && paid}
                disabled={true}
                handler={() => courseDetailBtnHandler("pro")}
              />
            </div>
          </motion.div>

          <motion.div
            whileHover={{
              scale: 1.05,
              boxShadow: "0 0 8px 0.5px #313538",
            }}
            className={styles.pricing_card}
          >
            <div className={styles.card_img_cont}>
              <div className={styles.card_img_cont_sub_one}></div>
              <div className={styles.pricing_card_float}>
                <div className={styles.pricing_card_float_sub}>
                  <span>ANALYSIS</span>
                </div>
              </div>
              <span>₹ 599</span>
            </div>
            <div className={styles.pricing_card_text_cont}>
              <span>STRATEGIC INSIGHTS - HYBRID ANALYSIS PLAN</span>
            </div>

            <div className={styles.pricing_card_features_text_cont}>
              <span>Access to 6+ potential analysis per week</span>
              <span>Get timely high-impact stock analysis.</span>
              <span>Access to all free analysis</span>
              <span>1 year unlimited access</span>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0c969a",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    (courseType === "analysis" && paid) ||
                    (courseType === "pro" && paid)
                  ) {
                    return;
                  } else {
                    courseDetailBtnHandler("analysis");
                  }
                }}
              >
                view more
              </span>
            </div>

            <div className={styles.btn_cont}>
              <ButtonComponent
                text={
                  courseType === "analysis" && paid
                    ? "You purchased this"
                    : courseType === "pro" && paid
                    ? "You already have access"
                    : "Get Offer"
                }
                disabled={
                  (courseType === "analysis" && paid) ||
                  (courseType === "pro" && paid)
                }
                handler={() => courseDetailBtnHandler("analysis")}
              />
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default PricingPage;
