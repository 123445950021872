import ButtonComponent from "../../../../../components/buttonComponent/buttonComponent";
import CarouselComponent from "../../../../../components/carousel/carouselWhatWeDo";
import TypingTextSingle from "../../../../../components/typingText/typingTextSingle";
import TypingTextArray from "../../../../../components/typingText/typingTexts";
import styles from "./bannerContainer.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { IoLocationOutline } from "react-icons/io5";

import { IoIosClose } from "react-icons/io";

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

function BannerContainer() {
  const elementRef = useRef(null);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 924) {
      setShowModal(false);
    }
  }, [windowWidth]);

  function signupBtnHandler(event) {
    event.preventDefault();
    navigate("/signup");
  }

  function closeModal() {
    setShowModal(false);
  }

  function whatWeDoBtnHandler() {
    if (windowWidth > 924) {
      setShowModal(true);
    } else {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <section className={styles.banner_container}>
      <div className={styles.banner_container_sub}>
        <div className={styles.banner_container_sub_slider}>
          <motion.div className={styles.bar_out}>
            <motion.div
              className={styles.bar_in}
              initial={{ width: "0%" }}
              animate={{
                width: [
                  "10%",
                  "20%",
                  "30%",
                  "40%",
                  "50%",
                  "60%",
                  "70%",
                  "80%",
                  "90%",
                  "100%",
                ],
              }}
              transition={{
                duration: 0.5,
                stiffness: [100, 200, 300, 400, 500, 600, 700],
                damping: 10,
              }}
            >
              <motion.h1
                className={styles.bar_out_text}
                initial={{ opacity: "0%", scale: 0 }}
                animate={{
                  opacity: [0, 0, 1],
                  scale: [0, 0.3, 0.6, 1, 1.3, 1],
                }}
                transition={{ delay: 3 }}
              >
                -3.2 %
              </motion.h1>

              <motion.div
                className={styles.arrow_outer_one}
                initial={{ opacity: "0%" }}
                animate={{
                  opacity: [0, 0, 0, 0, 1],
                }}
                transition={{ delay: 5 }}
              >
                <motion.div
                  className={styles.arrow_in_one}
                  initial={{ opacity: "0%", height: "0%" }}
                  animate={{
                    opacity: [1],
                    height: [
                      "0%",
                      "10%",
                      "20%",
                      "30%",
                      "40%",
                      "50%",
                      "60%",
                      "70%",
                      "80%",
                      "90%",
                      "100%",
                    ],
                  }}
                  transition={{ delay: 5.5 }}
                ></motion.div>
              </motion.div>

              <div className={styles.typing_text_cont_one}>
                <TypingTextSingle text={"Tired of being here"} seconds={6000} />
              </div>
            </motion.div>
          </motion.div>

          <motion.div className={styles.bar_out_two}>
            <motion.div
              className={styles.bar_in_two}
              initial={{ width: "0%" }}
              animate={{
                width: [
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "10%",
                  "20%",
                  "30%",
                  "40%",
                  "50%",
                  "60%",
                  "70%",
                  "80%",
                  "90%",
                  "100%",
                ],
              }}
              transition={{
                duration: 1,
                stiffness: [100, 200, 300, 400, 500, 600, 700],
                damping: 10,
              }}
            >
              <motion.h1
                className={styles.bar_out_two_text}
                initial={{ opacity: "0%", scale: 0 }}
                animate={{
                  opacity: [0, 0, 1],
                  scale: [0, 0.3, 0.6, 1, 1.3, 1],
                }}
                transition={{ delay: 3.5 }}
              >
                to
              </motion.h1>
            </motion.div>
          </motion.div>

          <motion.div className={styles.bar_out_three}>
            <motion.div
              className={styles.bar_in_three}
              initial={{ width: "0%" }}
              animate={{
                width: [
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "10%",
                  "20%",
                  "30%",
                  "40%",
                  "50%",
                  "60%",
                  "70%",
                  "80%",
                  "90%",
                  "100%",
                ],
              }}
              transition={{
                duration: 2,
                stiffness: [100, 200, 300, 400, 500, 600, 700],
                damping: 10,
              }}
            >
              <motion.h1
                className={styles.bar_out_three_text}
                initial={{ opacity: "0%", scale: 0 }}
                animate={{
                  opacity: [0, 0, 0, 1],
                  scale: [0, 0.3, 0.6, 1, 1.3, 1],
                }}
                transition={{ delay: 4 }}
              >
                +32%
              </motion.h1>
            </motion.div>
          </motion.div>

          <motion.div className={styles.bar_out_four}>
            <motion.div
              className={styles.bar_in_four}
              initial={{ width: "0%" }}
              animate={{
                width: [
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "0%",
                  "10%",
                  "20%",
                  "30%",
                  "40%",
                  "50%",
                  "60%",
                  "70%",
                  "80%",
                  "90%",
                  "100%",
                ],
              }}
              transition={{
                duration: 3,
                stiffness: [100, 200, 300, 400, 500, 600, 700],
                damping: 10,
              }}
            >
              <motion.div
                className={styles.bar_out_four_icon}
                initial={{ opacity: "0%", scale: 0 }}
                animate={{
                  opacity: [0, 0, 0, 1],
                  scale: [0, 0.3, 0.6, 1, 1.3, 1],
                }}
                transition={{ delay: 4.5 }}
              >
                <IoLocationOutline
                  size={windowWidth <= 581 ? 40 : windowWidth <= 705 ? 50 : 85}
                  color="#3cb179"
                />
              </motion.div>

              <motion.div
                className={styles.arrow_outer_four}
                initial={{ opacity: "0%" }}
                animate={{
                  opacity: [0, 0, 0, 0, 1],
                }}
                transition={{ delay: 5 }}
              >
                <motion.div
                  className={styles.arrow_in_four}
                  initial={{ opacity: "0%", width: "100%" }}
                  animate={{
                    opacity: [1],
                    width: [
                      "0%",
                      "0%",
                      "10%",
                      "20%",
                      "30%",
                      "40%",
                      "50%",
                      "60%",
                      "70%",
                      "80%",
                      "90%",
                      "100%",
                    ],
                  }}
                  transition={{ delay: 8.3 }}
                ></motion.div>
              </motion.div>

              <div className={styles.typing_text_cont_four}>
                <TypingTextSingle
                  text={"Struggling hard to reach here..."}
                  seconds={9000}
                />
              </div>

              <motion.div
                className={styles.arrow_outer_four_one}
                initial={{ opacity: "0%" }}
                animate={{
                  opacity: [0, 0, 0, 0, 1],
                }}
                transition={{ delay: 5 }}
              >
                <motion.div
                  className={styles.arrow_in_four_one}
                  initial={{ opacity: "0%", height: "100%" }}
                  animate={{
                    opacity: [1],
                    height: [
                      "0%",
                      "0%",
                      "10%",
                      "20%",
                      "30%",
                      "40%",
                      "50%",
                      "60%",
                      "70%",
                      "80%",
                      "90%",
                      "100%",
                    ],
                  }}
                  transition={{ delay: 12.5 }}
                ></motion.div>
              </motion.div>

              <div className={styles.typing_text_cont_four_one}>
                <TypingTextSingle
                  text={"We are here to help you"}
                  seconds={13300}
                />
              </div>

              <motion.div
                className={styles.arrow_outer_four_two}
                initial={{ opacity: "0%" }}
                animate={{
                  opacity: [0, 0, 0, 0, 1],
                }}
                transition={{ delay: 5 }}
              >
                <motion.div
                  className={styles.arrow_in_four_two}
                  initial={{ opacity: "0%", height: "100%" }}
                  animate={{
                    opacity: [1],
                    height: [
                      "0%",
                      "0%",
                      "10%",
                      "20%",
                      "30%",
                      "40%",
                      "50%",
                      "60%",
                      "70%",
                      "80%",
                      "90%",
                      "100%",
                    ],
                  }}
                  transition={{ delay: 15.8 }}
                ></motion.div>
              </motion.div>

              <motion.button
                className={styles.what_we_do_btn}
                initial={{ opacity: "0%" }}
                animate={{
                  opacity: [0, 0, 1],
                }}
                transition={{ delay: 16.5 }}
                whileHover={{
                  scale: 1.05,
                }}
                onClick={whatWeDoBtnHandler}
              >
                Okay, what we do ?
              </motion.button>
            </motion.div>
          </motion.div>

          {/* <motion.div
            className={styles.array_text}
            initial={{ opacity: "0%" }}
            animate={{
              opacity: [0, 1],
            }}
            transition={{ delay: 18 }}
          >
            <span className={styles.array_text_span}>
              Consistency in trading gives you&nbsp;
            </span>
            <TypingTextArray />
          </motion.div> */}
        </div>

        <div className={styles.consider_text}>
          <TypingTextSingle
            text={"Consider this as your portfolio..."}
            seconds={0}
            textSpeed={20}
          />
        </div>

        <div className={styles.ellipsis_design}>
          {/* <FaEllipsis size={70} color="#313538" /> */}
          {/* <Bounce size={30} color="#0c969a" /> */}
          <motion.div
            className={styles.scroll_downs}
            initial={{ opacity: "0%" }}
            animate={{
              opacity: [0, 0, 1],
            }}
            transition={{ delay: 17 }}
          >
            <div className={styles.mousey}>
              <div className={styles.scroller}></div>
            </div>
          </motion.div>
        </div>

        {windowWidth <= 924 && (
          <div className={styles.slider_cont} ref={elementRef}>
            <CarouselComponent closeModal={closeModal} />
          </div>
        )}
      </div>

      <Modal
        isOpen={showModal}
        // onRequestClose={closeModal}
        contentLabel="OTP Modal"
        style={{
          overlay: {
            position: "fixed",
            zIndex: 1020,
            margin: "auto",
            width: "100vw",
            height: "100vh",
            background: "rgba(30, 30, 30, 1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
          },
          content: {
            background: "rgba(30, 30, 30, 1)",
            minWidth: "70%",
            height: "31rem",
            overflowY: "auto",
            border: "1px solid rgba(30, 30, 30, 0.9)",
            borderRadius: "0.3rem",
            position: "unset",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <div className={styles.modal_div}>
          <CarouselComponent closeModal={closeModal} />
        </div>
      </Modal>
    </section>
  );
}

export default BannerContainer;

//left: 6.7%; left: 32%; left: 57.3%; left: 82.8%;
//left: 5rem; left: 24rem; left: 43rem; left: 62rem;
