import ButtonComponent from "../../../../../components/buttonComponent/buttonComponent";
import styles from "./freeCourseContainer.module.css";
import { useNavigate } from "react-router-dom";
import FreeCourseImg from "../../../../../assets/images/free_cont.svg";

function FreeCourseContainer() {
  const navigate = useNavigate();

  return (
    <section className={styles.free_course_container}>
      <div className={styles.free_course_container_sub}>
        <div className={styles.free_course}>
          <div className={styles.free_course_text_cont}>
            <h2>Free Stock Market Basics</h2>
            <p>
              Unlock the secrets of successful trading with our comprehensive
              and free stock market basics guide. Start learning today and
              empower yourself to make informed investment decisions.
            </p>

            <ButtonComponent
              text={"Start Learning"}
              handler={() => navigate("/free-course")}
            />
          </div>

          <div className={styles.img_cont}>
            <img src={FreeCourseImg} alt="trade-setup-image" />
          </div>
        </div>

        <div className={styles.free_analysis}>
          <div className={styles.free_analysis_text_cont}>
            <h2>Stock Analysis for Traders</h2>
            <p>
              Get expert insights on stocks for free and make informed trading
              decisions now.
            </p>
          </div>

          <div className={styles.free_analysis_btn_cont}>
            <ButtonComponent
              text={"View Analyses"}
              handler={() => navigate("/analysis-statistics")}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FreeCourseContainer;
