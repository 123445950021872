import styles from "./tacticsContainer.module.css";
import { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import SliderOne from "../../../../../assets/images/slider_one.svg";

function TacticsContainer() {
  const { scrollY } = useScroll();
  const [tab, setTab] = useState("fundamental");
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const opacityTextH2 = useTransform(
    scrollY,
    [100, 150, 200, 250, 300, 450, 550],
    [0, 0.2, 0.2, 0.3, 0.4, 0.7, 1]
  );
  const opacityTextH2Mbl = useTransform(
    scrollY,
    [810, 860, 910, 960, 1010, 1060, 1110],
    [0, 0.2, 0.2, 0.3, 0.4, 0.7, 1]
  );

  const yTextH2 = useTransform(
    scrollY,
    [100, 150, 200, 250, 300, 450, 550],
    [500, 550, 400, 450, 300, 100, 0]
  );

  const yTextH2Mbl = useTransform(
    scrollY,
    [810, 860, 910, 960, 1010, 1060, 1110],
    [250, 230, 200, 150, 100, 50, 0]
  );

  const consistencyImgX = useTransform(
    scrollY,
    [400, 500, 600, 700, 800, 900],
    [400, 300, 200, 100, 50, 0]
  );

  const consistencyImgXMbl = useTransform(
    scrollY,
    [1400, 1500, 1600, 1700, 1800, 1900],
    [400, 300, 200, 100, 50, 0]
  );

  const opacityImgX = useTransform(
    scrollY,
    [400, 500, 600, 700, 800, 900],
    [0, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const opacityImgXMbl = useTransform(
    scrollY,
    [1400, 1500, 1600, 1700, 1800, 1900],
    [0, 0.1, 0.3, 0.5, 0.6, 1]
  );

  const consistencyTextBoxX = useTransform(
    scrollY,
    [400, 500, 600, 700, 800, 900],
    [-400, -300, -200, -100, -50, 0]
  );

  const consistencyTextBoxXMbl = useTransform(
    scrollY,
    [850, 950, 1050, 1150, 1250, 1350],
    [-400, -300, -200, -100, -50, 0]
  );

  const opacityTextBoxX = useTransform(
    scrollY,
    [400, 500, 600, 700, 800, 900],
    [0, 0.3, 0.5, 0.7, 0.9, 1]
  );

  const opacityTextBoxXMbl = useTransform(
    scrollY,
    [850, 950, 1050, 1150, 1250, 1350],
    [0, 0.1, 0.3, 0.5, 0.6, 1]
  );

  return (
    <section className={styles.consistency_container}>
      <div className={styles.consistency_container_sub}>
        {windowWidth > 670 ? (
          <div className={styles.tab_cont}>
            <div
              className={styles.tab}
              style={{
                backgroundColor: tab === "fundamental" ? "#313538" : "",
              }}
              onClick={() => setTab("fundamental")}
            >
              Fundamental
            </div>
            <div
              className={styles.tab}
              style={{ backgroundColor: tab === "technical" ? "#313538" : "" }}
              onClick={() => setTab("technical")}
            >
              Technical
            </div>
            <div
              className={styles.tab}
              style={{ backgroundColor: tab === "risk" ? "#313538" : "" }}
              onClick={() => setTab("risk")}
            >
              {windowWidth > 670 ? "Risk Management" : "Manage"}
            </div>
          </div>
        ) : (
          <div className={styles.tab_cont}>
            <div
              className={styles.tab}
              style={{ backgroundColor: tab === "technical" ? "#313538" : "" }}
              onClick={() => setTab("technical")}
            >
              Technical
            </div>
            <div
              className={styles.tab}
              style={{
                backgroundColor: tab === "fundamental" ? "#313538" : "",
              }}
              onClick={() => setTab("fundamental")}
            >
              Fundamental
            </div>
            <div
              className={styles.tab}
              style={{ backgroundColor: tab === "risk" ? "#313538" : "" }}
              onClick={() => setTab("risk")}
            >
              {windowWidth > 670 ? "Risk Management" : "Manage"}
            </div>
          </div>
        )}
        {windowWidth > 924 && (
          <div className={styles.content_cont}>
            <motion.h2 style={{ y: yTextH2, opacity: opacityTextH2 }}>
              {tab === "fundamental" &&
                "Unleashing Fundamental Analysis for Trading Triumph"}
              {tab === "technical" &&
                "Harnessing Technical Analysis Techniques"}
              {tab === "risk" && "Mastering Risk Management Strategies"}
            </motion.h2>

            <div className={styles.text_img_cont}>
              <div className={styles.text_main_cont}>
                <motion.div
                  className={styles.text_cont}
                  style={{ x: consistencyTextBoxX, opacity: opacityTextBoxX }}
                >
                  <h3>
                    {tab === "fundamental" && "Fundamental Analysis Mastery"}
                    {tab === "technical" && "Technical Analysis Mastery"}
                    {tab === "risk" && "Risk management Mastery"}
                  </h3>
                  <p>
                    {tab === "fundamental" &&
                      "Unlock the secrets of fundamental analysis to elevate your trading prowess. Dive deep into company financials, examining revenue, earnings, and growth potential. Explore industry trends, market positioning, and competitive advantages. Elevate your trading game with fundamental analysis."}
                    {tab === "technical" &&
                      "Maximize trading profits with technical analysis mastery. Learn to interpret charts, patterns, and indicators to forecast price movements. Explore support and resistance levels, trend lines, and oscillators. Discover entry and exit signals for precise timing."}
                    {tab === "risk" &&
                      "Protect your capital and amplify your returns with robust risk management techniques. Understand the importance of risk-reward ratios and position sizing. Learn to set stop-loss orders and manage trade exposure effectively."}
                  </p>
                </motion.div>
              </div>

              <div className={styles.img_cont}>
                <motion.img
                  style={{ x: consistencyImgX, opacity: opacityImgX }}
                  src={SliderOne}
                />
              </div>
            </div>
          </div>
        )}
        {windowWidth <= 924 && (
          <div className={styles.content_cont}>
            <motion.h2 style={{ y: yTextH2Mbl, opacity: opacityTextH2Mbl }}>
              {tab === "fundamental" &&
                "Unleashing Fundamental Analysis for Trading Triumph"}
              {tab === "technical" &&
                "Harnessing Technical Analysis Techniques"}
              {tab === "risk" && "Mastering Risk Management Strategies"}
            </motion.h2>

            <div className={styles.text_img_cont}>
              <div className={styles.text_main_cont}>
                <motion.div
                  className={styles.text_cont}
                  style={{
                    x: consistencyTextBoxXMbl,
                    opacity: opacityTextBoxXMbl,
                  }}
                >
                  <h3>
                    {tab === "fundamental" && "Fundamental Analysis Mastery"}
                    {tab === "technical" && "Technical Analysis Mastery"}
                    {tab === "risk" && "Risk management Mastery"}
                  </h3>
                  <p>
                    {tab === "fundamental" &&
                      "Unlock the secrets of fundamental analysis to elevate your trading prowess. Dive deep into company financials, examining revenue, earnings, and growth potential. Explore industry trends, market positioning, and competitive advantages. Elevate your trading game with fundamental analysis."}
                    {tab === "technical" &&
                      "Maximize trading profits with technical analysis mastery. Learn to interpret charts, patterns, and indicators to forecast price movements. Explore support and resistance levels, trend lines, and oscillators. Discover entry and exit signals for precise timing."}
                    {tab === "risk" &&
                      "Protect your capital and amplify your returns with robust risk management techniques. Understand the importance of risk-reward ratios and position sizing. Learn to set stop-loss orders and manage trade exposure effectively."}
                  </p>
                </motion.div>
              </div>

              <div className={styles.img_cont}>
                <motion.img
                  style={{ x: consistencyImgXMbl, opacity: opacityImgXMbl }}
                  src={SliderOne}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default TacticsContainer;
