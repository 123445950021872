import { Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/layout";
import HomePage from "./pages/beforeLoggedInPages/homePage/homePage";
import DashboardPage from "./pages/beforeLoggedInPages/dashboardPage/dashboard";
import SigninPage from "./pages/signinPage/signinPage";
import SignupPage from "./pages/signupPage/signupPage";
import MyCoursePage from "./pages/afterLoggedInPages/myCoursesPage/myCourses";
import { checkAuthTokenPaid, checkAuthTokenNormal } from "./utils/auth/auth";
import AnalysisStatsPage from "./pages/beforeLoggedInPages/analysisStatsPage/analysisStatsPage";
import PrivacyPolicyPage from "./pages/generalPages/privacyPolicy/privacyPolicyPage";
import { AuthContext } from "./components/store/context/authContextProvider";
import { useContext } from "react";
import AnalysisPage from "./pages/afterLoggedInPages/analysisPage/analysisPage";
import TechnicalContentPage from "./pages/afterLoggedInPages/myCoursesPage/contents/technicalContentPage";
import FundamentalContentPage from "./pages/afterLoggedInPages/myCoursesPage/contents/fundamentalContentPage";
import PricingPage from "./pages/beforeLoggedInPages/pricingPage/pricingPage";
import CheckoutPage from "./pages/beforeLoggedInPages/pricingPage/checkoutPage/checkoutPage";
import CourseDetailsPage from "./pages/beforeLoggedInPages/pricingPage/detailsPage/courseDetailsPage";
import FreeCoursePage from "./pages/beforeLoggedInPages/freeCourse/freeCourse";
import FreeCourseContentPage from "./pages/beforeLoggedInPages/freeCourse/contents/freeCourseContentPage";
import ProfilePage from "./pages/afterLoggedInPages/profilePage/profilePage";
import TermsOfServicePage from "./pages/generalPages/termsOfService/termsOfService";
import RefundPolicyPage from "./pages/generalPages/refundPolicy/refundPolicy";
import FaqPage from "./pages/generalPages/faq/faq";
import AboutUs from "./pages/generalPages/aboutUs/aboutUs";
import PaymentStatusPage from "./pages/generalPages/paymentStatusPage/paymentStatuspage";
import FunnelPageForAnalysis from "./pages/generalPages/funnelPages/funnelPageForAnalysis/funnelPageForAnalysis";

function App() {
  const authCtx = useContext(AuthContext);

  return (
    <div className="App">
      <Layout>
        {authCtx.isValidToken && authCtx.paid ? (
          <Routes>
            <Route exact path="/course" element={<MyCoursePage />} />
            <Route exact path="/analyses" element={<AnalysisPage />} />
            <Route exact path="/paid-dashboard" element={<DashboardPage />} />
            <Route exact path="/user-profile" element={<ProfilePage />} />
            <Route
              exact
              path="/technical-content"
              element={<TechnicalContentPage />}
            />
            <Route
              exact
              path="/fundamental-content"
              element={<FundamentalContentPage />}
            />
            <Route exact path="/upgrade" element={<PricingPage />} />
            <Route exact path="/plan-details" element={<CourseDetailsPage />} />
            <Route
              exact
              path="/checkout"
              element={
                authCtx.userSelectedCourseFromPricingPage !== "none" ? (
                  <CheckoutPage />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route exact path="*" element={<Navigate to="/course" replace />} />
            <Route exact path="/status/:id" element={<PaymentStatusPage />} />
          </Routes>
        ) : authCtx.isValidToken && !authCtx.paid ? (
          <Routes>
            <Route
              exact
              path="/normal-user-dashboard"
              element={<DashboardPage />}
            />
            <Route
              exact
              path="/normal-user-profile"
              element={<ProfilePage />}
            />
            <Route
              exact
              path="/analysis-statistics"
              element={<AnalysisStatsPage />}
            />
            {/* <Route exact path="/free-course" element={<FreeCoursePage />} />
            <Route
              exact
              path="/free-course-fundamental-content"
              element={<FreeCourseContentPage />}
            /> */}
            <Route exact path="/normal-user-plan" element={<PricingPage />} />
            <Route
              exact
              path="/plan-details"
              element={
                authCtx.userSelectedCourseFromPricingPage !== "none" ? (
                  <CourseDetailsPage />
                ) : (
                  <Navigate to="/normal-user-dashboard" replace />
                )
              }
            />
            <Route
              exact
              path="/checkout"
              element={
                authCtx.userSelectedCourseFromPricingPage !== "none" ? (
                  <CheckoutPage />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route exact path="/status/:id" element={<PaymentStatusPage />} />
            <Route
              exact
              path="*"
              element={<Navigate to="/normal-user-dashboard" replace />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/dashboard" element={<DashboardPage />} />

            <Route
              exact
              path="/analysis-statistics"
              element={<AnalysisStatsPage />}
            />
            <Route exact path="/signin" element={<SigninPage />} />
            <Route exact path="/signup" element={<SignupPage />} />
            {/* <Route exact path="/free-course" element={<FreeCoursePage />} /> */}
            {/* <Route
              exact
              path="/free-course-fundamental-content"
              element={<FreeCourseContentPage />}
            /> */}
            <Route exact path="/plan" element={<PricingPage />} />
            <Route
              exact
              path="/plan-details"
              element={
                authCtx.userSelectedCourseFromPricingPage !== "none" ? (
                  <CourseDetailsPage />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              exact
              path="/checkout"
              element={
                authCtx.userSelectedCourseFromPricingPage !== "none" ? (
                  <CheckoutPage />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicyPage />}
            />
            <Route
              exact
              path="/terms-of-service"
              element={<TermsOfServicePage />}
            />
            <Route exact path="/faq" element={<FaqPage />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/refund-policy" element={<RefundPolicyPage />} />
            <Route exact path="/status/:id" element={<PaymentStatusPage />} />
            <Route
              exact
              path="/unlock-stock-market-success-with-hybrid-analysis"
              element={<FunnelPageForAnalysis />}
            />
            <Route exact path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </Layout>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        toastStyle={{ backgroundColor: "#242627", color: "#c9c8c7" }}
      />
    </div>
  );
}

export default App;
