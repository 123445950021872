import Logo from "../../../../../../assets/images/logo.svg";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaTelegramPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./footerContainer.module.css";

function FooterContainer() {
  const navigate = useNavigate();

  const handleFacebookClick = () => {
    window.location.href =
      "https://www.facebook.com/profile.php?id=61557963036016";
  };

  const handleInstagramClick = () => {
    window.location.href =
      "https://www.instagram.com/charteey_?igsh=MTc0anJxMmlrazYzdQ==";
  };

  const handleLinkedinClick = () => {
    window.location.href = "https://www.linkedin.com/in/charteey-8a624a303";
  };

  const handleTelegramClick = () => {
    window.location.href = "https://t.me/+I28mCKyYb0VmMjA1";
  };

  return (
    <section className={styles.footer_container}>
      <div className={styles.footer_container_sub}>
        <div className={styles.footer}>
          <div className={styles.footer_top}>
            <div className={styles.footer_top_sub1}>
              <div className={styles.name_address}>
                <div className={styles.logo_cont}>
                  <img src={Logo} />
                  <h2>Charteey</h2>
                </div>

                <div className={styles.address_cont}>
                  <p>A Synerixs product.</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer_bottom}>
            <a style={{ color: "#fff", fontWeight: "600", cursor: "pointer" }}>
              Synerixs.{" "}
            </a>
            <p>&copy; 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FooterContainer;
