import styles from "../privacyPolicy/privacyPolicyPage.module.css";
import stylesOne from "./termsOfservice.module.css";

function TermsOfServicePage() {
  return (
    <section className={styles.privacy_page_container}>
      <div className={stylesOne.top_image_container}>
        <span>Terms Of service</span>
        <span>
          Charteey values your trust. Our Terms outline website rules. By
          accessing, you agree. Please review for understanding.
        </span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span></span>
          <span>
            These Terms of Service ("Terms") govern your use of Charteey,
            including all content, functionality, and services offered on or
            through Charteey (www.charteey.com). Please read these Terms
            carefully before using the Site. By accessing or using any part of
            the Site, you agree to be bound by these Terms. If you do not agree
            to all the terms and conditions of this agreement, then you may not
            access the Site or use any services.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>User Accounts</span>
          <span>
            In order to access certain features of the Site, you may be required
            to create an account. By creating an account, you agree to provide
            accurate, current, and complete information. You are responsible for
            maintaining the confidentiality of your account and password, and
            you agree to accept responsibility for all activities that occur
            under your account or password. You may not use as a username the
            name of another person or entity or that is not lawfully available
            for use, a name or trademark that is subject to any rights of
            another person or entity other than you, without appropriate
            authorization.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Content</span>
          <span>
            The content on the Site is for informational purposes only and
            should not be considered financial advice. Charteey does not provide
            investment, legal, or tax advice. You should consult with a
            qualified professional before making any financial decisions. Users
            may submit content such as comments, posts, and messages. By
            submitting content, you grant Charteey a non-exclusive,
            royalty-free, perpetual, irrevocable, and fully sublicensable right
            to use, reproduce, modify, adapt, publish, translate, create
            derivative works from, distribute, and display such content
            throughout the world in any media. Charteey reserves the right to
            remove or edit any content submitted by users for any reason,
            without prior notice.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Intellectual Property</span>
          <span>
            The Site and its original content, features, and functionality are
            owned by Charteey and are protected by international copyright,
            trademark, patent, trade secret, and other intellectual property or
            proprietary rights laws. You may not modify, reproduce, distribute,
            create derivative works of, publicly display, publicly perform,
            republish, download, store, or transmit any of the material on our
            Site.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Disclaimer</span>
          <span>
            Your use of the Site is at your own risk. The Site is provided on an
            "as is" and "as available" basis, without any warranties of any
            kind, either express or implied. Charteey does not warrant that the
            Site will be uninterrupted, secure, or error-free, that defects will
            be corrected, or that the Site or the server that makes it available
            are free of viruses or other harmful components.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Limitation of Liability</span>
          <span>
            In no event shall Charteey, nor its directors, employees, partners,
            agents, suppliers, or affiliates, be liable for any indirect,
            incidental, special, consequential, or punitive damages, including
            without limitation, loss of profits, data, use, goodwill, or other
            intangible losses, resulting from (i) your access to or use of or
            inability to access or use the Site; (ii) any conduct or content of
            any third party on the Site; (iii) any content obtained from the
            Site; and (iv) unauthorized access, use, or alteration of your
            transmissions or content, whether based on warranty, contract, tort
            (including negligence), or any other legal theory, whether or not we
            have been informed of the possibility of such damage, and even if a
            remedy set forth herein is found to have failed of its essential
            purpose.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Indemnification</span>
          <span>
            You agree to defend, indemnify, and hold harmless Charteey, its
            affiliates, licensors, and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses, or fees (including reasonable attorneys' fees) arising out
            of or relating to your violation of these Terms or your use of the
            Site.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Governing Law</span>
          <span>
            These Terms shall be governed by and construed in accordance with
            the laws of India, without regard to its conflict of law provisions.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Changes</span>
          <span>
            Charteey reserves the right, at its sole discretion, to modify or
            replace these Terms at any time. If a revision is material, we will
            provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Disclaimer Regarding Market Risk</span>
          <span>
            Please note that all analyses, information, and content provided on
            this website, including but not limited to stock analyses, trading
            courses, and educational materials, are for informational purposes
            only. The analyses shared are subject to market risk, and the
            trading of stocks involves substantial risk of loss and is not
            suitable for all investors. Charteey does not guarantee the
            accuracy, completeness, or timeliness of any information provided on
            this website. Users are solely responsible for their own investment
            decisions and should seek the advice of a qualified financial
            advisor before making any investment. By accessing or using this
            website, you acknowledge and agree that Charteey is not responsible
            for any losses incurred as a result of your reliance on the
            information provided on this website. Charteey shall not be liable
            for any direct, indirect, incidental, special, consequential, or
            punitive damages arising out of or related to your use of this
            website or any content available on this website.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Contact Us</span>
          <span>
            If you have any questions about these Terms, please contact us at
            support@charteey.com.
          </span>
        </div>

        <div className={styles.head_date_container}>
          <span>Last updated :</span>
          <span> March 12, 2024</span>
        </div>
      </div>
    </section>
  );
}

export default TermsOfServicePage;
