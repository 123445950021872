import styles from "../privacyPolicy/privacyPolicyPage.module.css";
import stylesOne from "./paymentStatusPage.module.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../components/store/context/authContextProvider";
import SuccessImg from "../../../assets/images/paySuccessIcon.svg";
import FailedImg from "../../../assets/images/payFailedIcon.svg";
import NoUserFound from "../../../assets/images/noUserFound.svg";
import Wrong from "../../../assets/images/wrong.svg";
import { color } from "framer-motion";

function PaymentStatusPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [showModal, setShowModal] = useState(true);
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (authCtx.paymentStatus === false) {
      authCtx.setPaymentStatus(id);
    }
    // console.log("oneee");
  }, [id]);

  useEffect(() => {
    if (authCtx.paymentStatus !== false) {
      const timer = setTimeout(() => {
        if (logoutTimer > 0) {
          setLogoutTimer(logoutTimer - 1);
        } else {
          if (authCtx.courseType !== "none" && authCtx.paid) {
            authCtx.setMainLoader(true);
            navigate("/course", { replace: true });
            authCtx.setPaymentStatus(false);
            authCtx.setUpdatingPlan(false);
            authCtx.logout();
            window.location.reload();
          } else if (
            (authCtx.courseType !== "none" || authCtx.courseType === "none") &&
            !authCtx.paid
          ) {
            authCtx.setMainLoader(true);
            navigate("/normal-user-dashboard", { replace: true });
            authCtx.setPaymentStatus(false);
            authCtx.setUpdatingPlan(false);
            authCtx.logout();
            window.location.reload();
          } else {
            authCtx.setMainLoader(true);
            navigate("/", { replace: true });
            authCtx.setPaymentStatus(false);
            authCtx.setUpdatingPlan(false);
            window.location.reload();
          }
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [logoutTimer, authCtx.paymentStatus]);

  return (
    <section className={styles.privacy_page_container}>
      <Modal
        isOpen={showModal}
        // onRequestClose={closeModal}
        contentLabel="OTP Modal"
        style={{
          overlay: {
            position: "fixed",
            zIndex: 1020,
            margin: "auto",
            paddingTop: "3rem",
            width: "100vw",
            height: "100vh",
            background: "rgba(30, 30, 30, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            padding: "1rem",
            overflow: "auto",
          },
          content: {
            background: "#0c0d0e",
            width: windowWidth <= 460 ? "90%" : "30rem",
            height: "fit-content",
            overflowY: "auto",
            border: "0px solid #3f4345",
            borderRadius: "1rem",
            position: "unset",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <div className={stylesOne.modal_div}>
          {id === "success" && <img src={SuccessImg} alt="success-icon" />}
          {id === "failed" && <img src={FailedImg} alt="failed-icon" />}
          {id === "noUser" && <img src={NoUserFound} alt="no-user-icon" />}
          {id === "wrong" && (
            <img src={Wrong} alt="something-went-wrong-icon" />
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            {id === "success" && (
              <span className={stylesOne.message}>
                Thank you for your payment! Let's achieve trading success
                together!.{" "}
                {authCtx.isValidToken
                  ? "You will be logged out automatically"
                  : ""}{" "}
                Please log in again to access your plan.
              </span>
            )}
            {id === "failed" && (
              <span className={stylesOne.message}>
                Unfortunately, your payment did not go through. Please try again
                to access your plan.{" "}
                {authCtx.isValidToken
                  ? "You will be logged out automatically"
                  : ""}{" "}
                Please log in again to continue.
              </span>
            )}
            {id === "noUser" && (
              <>
                <span className={stylesOne.message}>
                  No user found with the provided details. Please try again to
                  access your plan.{" "}
                  {authCtx.isValidToken
                    ? "You will be logged out automatically"
                    : ""}{" "}
                  Please log in again to continue.
                </span>
                <span
                  className={stylesOne.message}
                  style={{
                    marginTop: "1rem",
                    fontSize: "0.7rem",
                    color: "#EC2227",
                  }}
                >
                  If your money has been debited, please contact us immediately.
                </span>
              </>
            )}
            {id === "wrong" && (
              <>
                <span className={stylesOne.message}>
                  Oops! Something went wrong. Please try again to access your
                  plan.{" "}
                  {authCtx.isValidToken
                    ? "You will be logged out automatically"
                    : ""}{" "}
                  Please log in again to continue.
                </span>
                <span
                  className={stylesOne.message}
                  style={{
                    marginTop: "1rem",
                    fontSize: "0.7rem",
                    color: "#EC2227",
                  }}
                >
                  If your money has been debited, please contact us immediately.
                </span>
              </>
            )}
          </div>

          <div>
            <span className={stylesOne.message}>
              {authCtx.isValidToken ? "Logging out" : "Redirecting"} in...{" "}
              <span
                style={{
                  color: "#16D298",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                {logoutTimer}
              </span>
            </span>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default PaymentStatusPage;
