import HybridImg from "../../../../../../assets/images/hybrid.svg";
import Marquee from "react-fast-marquee";
import styles from "./reviewSection.module.css";

function ReviewSection() {
  const reviewData = [
    {
      name: "Hanish",
      id: "Kerala",
      gender: "male",
      review:
        "As a beginner, I found stock analysis daunting, but Charteey made it straightforward. The hybrid analysis covers all the crucial metrics, and their recommendations have been spot on. My confidence and profits have soared.",
    },
    {
      name: "Deepak",
      id: "Tamilnadu",
      gender: "male",
      review:
        "I’ve tried several stock analysis tools, but Charteey stands out with its accuracy and ease of use. The alerts and recommendations are always timely and insightful, leading to consistent profits. Highly recommended!",
    },
    {
      name: "Ishani",
      id: "Kerala",
      gender: "male",
      review:
        "The hybrid analysis approach has transformed my trading strategy. The combination of fundamental and technical insights provides a clear, actionable path for each stock. Since adopting this method, my investments have been more profitable and secure. It’s a must-have tool for serious traders.",
    },
    {
      name: "Susil",
      id: "Telangana",
      gender: "male",
      review:
        "Charteey’s hybrid analysis is fantastic. It has helped me make smarter investment choices and grow my portfolio",
    },

    {
      name: "Arjun",
      id: "Maharastra",
      gender: "male",
      review:
        "Since I started using Charteey's hybrid analysis, my trading approach has completely changed. The mix of fundamental insights and technical analysis has given me clear guidance for each trade, making my decisions more informed and boosting my portfolio's performance consistently.",
    },
    {
      name: "Charita",
      id: "Karnataka",
      gender: "male",
      review:
        "Charteey has been a game-changer for my investment strategy. The detailed hybrid analysis provides a comprehensive view of each stock, helping me make informed decisions. My portfolio has seen steady growth since I started using their service",
    },
    {
      name: "Riyaz",
      id: "Tamilnadu",
      gender: "male",
      review:
        "Simple and effective stock picks have significantly boosted my portfolio's growth. The detailed analysis and timely updates have made a big difference.",
    },
    {
      name: "Abhik",
      id: "Kerala",
      gender: "male",
      review:
        "Thanks to Charteey, my investments are now based on solid analysis. My returns have significantly improved.",
    },
    {
      name: "Neel",
      id: "Gujarat",
      gender: "male",
      review:
        "I have found Charteey to be incredibly useful in navigating the complexities of the stock market. The analysis is detailed yet easy to understand, making it accessible for traders of all levels.",
    },
    {
      name: "Sunita",
      id: "Andhra Pradesh",
      gender: "male",
      review:
        "Using hybrid analysis has changed how I trade, offering clear paths with both fundamental and technical insights, making my investments more profitable",
    },
  ];

  return (
    <div className={styles.secret_cont}>
      <div className={styles.secret_cont_sub}>
        <div className={styles.secret_heading_cont}>
          <h2>Unable To Decide ?</h2>
          <p>
            Listen Stories Of Success From Other People who have Worked With
            hybrid analysis
          </p>
        </div>

        <Marquee pauseOnHover={true}>
          <div className={styles.marquee_cont_sub}>
            {reviewData.map((item) => {
              return (
                <div className={styles.marquee_card}>
                  <div className={styles.marquee_card_top}>
                    <div className={styles.profile_circle}>
                      {/* <img
                        src={UserAvatar}
                        alt="profile-pic"
                        width={55}
                        height={55}
                      /> */}
                    </div>

                    <div className={styles.user_detail_cont}>
                      <p className={styles.name}>{item.name}</p>
                      <p className={styles.id}>{`@${item.id}`}</p>
                    </div>

                    <div className={styles.insta_icon}>
                      {/* <MdVerified size={25} color={"#0c969a"} /> */}
                    </div>
                  </div>

                  <div className={styles.marquee_card_bottom}>
                    <span className={styles.review_text}>{item.review}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </Marquee>
      </div>
    </div>
  );
}

export default ReviewSection;
