import TickIcon from "../../../../../../assets/icons/tick.svg";
import HybridImg from "../../../../../../assets/images/hybrid.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./whatWillChange.module.css";

function WhatWillChange(props) {
  return (
    <div className={styles.benefits_cont}>
      <div className={styles.benefits_heading_cont}>
        <h2>What Will Change In Your Portfolio?</h2>
      </div>

      <div className={styles.benefits_cont_sub}>
        <div className={styles.benefit_top}>
          <div className={styles.benefit_box}>
            <span>
              You Unlock Powerful <b>Secrets to Identify High-Growth Stocks</b>{" "}
              Consistently
            </span>
            <div>01</div>
          </div>

          <div className={styles.benefit_box}>
            <span>
              Gain Solid Confidence in Making Timely, Informed Investment
              Decisions
            </span>
            <div>02</div>
          </div>
        </div>

        <div className={styles.benefit_top}>
          <div className={styles.benefit_box}>
            <div></div>
            <span>
              Fast-Track Your Portfolio Growth with{" "}
              <b>Increased Returns and Stability</b>
            </span>
            <div>03</div>
          </div>

          <div className={styles.benefit_box}>
            <div></div>
            <span>
              <b>More Profit, Less Risk, Greater Returns</b> if You Follow Our
              Proven Strategies
            </span>
            <div>04</div>
          </div>
        </div>
      </div>

      <div className={styles.one_btn_cont}>
        <button
          onClick={() =>
            props.cta("FUNNEL - WhatWill container CTA btn clicked")
          }
        >
          Get it for ₹299{" "}
          <div
            onClick={() =>
              props.cta("FUNNEL - WhatWill container CTA btn clicked")
            }
          >
            <MdKeyboardArrowRight color="#000" size={25} />
          </div>
        </button>
      </div>
    </div>
  );
}

export default WhatWillChange;
