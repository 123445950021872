import Upi from "../../../../assets/images/upi.svg";
import Visa from "../../../../assets/images/visa.svg";
import Rupay from "../../../../assets/images/rupay.svg";
import Mastercard from "../../../../assets/images/mastercard.svg";
import Redirect from "../../../../assets/images/redirect.svg";
import { toast } from "react-toastify";
import styles from "./checkoutPage.module.css";
import { useNavigate } from "react-router-dom";
import { CallPatchApiServicesWithTkn } from "../../../../utils/webServices/apiCalls";
import ButtonComponent from "../../../../components/buttonComponent/buttonComponent";
import { event } from "../../../../components/commonFunction/commonFunction";
import { CallPostApiServices } from "../../../../utils/webServices/apiCalls";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../components/store/context/authContextProvider";
import { Helmet } from "react-helmet";
import Modal from "react-modal";

function CheckoutPage() {
  const {
    userSelectedCourseFromPricingPage,
    isValidToken,
    paid,
    userEmail,
    courseType,
    token,
    userName,
    updatingPlan,
    setUpdatingPlan,
    ipAddress,
  } = useContext(AuthContext);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mblNo, setMblNo] = useState("");
  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameErr, setNameErr] = useState(null);
  const [emailErr, setEmailErr] = useState(null);
  const [mblNoErr, setMblNoErr] = useState(null);
  const [passwordErr, setPasswordErr] = useState(null);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);
  const [errFromBackend, setErrFromBackend] = useState(null);
  const [otpFromBackend, setOtpFromBackend] = useState("123");
  const [userEnteredOtp, setUserEnteredOtp] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [otpBtnLoader, setOtpBtnLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [buyNowLoader, setBuyNowLoader] = useState(false);

  useEffect(() => {
    // Function to be called
    if (ipAddress) {
      const myFunction3Sec = () => {
        event("StayEvent", "checkoutPage - user stayed 5secs", ipAddress);
      };

      // const myFunction10Sec = () => {
      //   event("StayEvent", "FunnelPage - user stayed 10secs");
      // };

      const timer1 = setTimeout(myFunction3Sec, 3000);

      // const timer2 = setTimeout(myFunction10Sec, 10000);

      return () => {
        clearTimeout(timer1);
        // clearTimeout(timer2);
      };
    }
  }, [ipAddress]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if ((isValidToken && !paid) || (isValidToken && paid)) {
      setEmail(userEmail);
      setName(userName);
      setMblNo(authCtx.mblNo);
    }
  }, [authCtx]);

  const data = {
    name: name,
    amount: userSelectedCourseFromPricingPage === "analysis" ? 299 : 1999,
    number: mblNo,
    MUID: "MUID" + name + Date.now(),
    transactionId: "T" + name + Date.now(),
    email: email,
    isUpdatingPlan: updatingPlan,
  };

  function signUpClickHandler(e) {
    e.preventDefault();

    if (isValidToken) {
      event(
        "BtnClick",
        `webpage-checkoutPage paynow btn clicked by registered user ${userEmail}`,
        "none"
      );
    } else {
      event("BtnClick", "webpage-checkoutPage paynow btn clicked", ipAddress);
    }
    if ((isValidToken && !paid) || (isValidToken && paid)) {
      handlePayment();
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      setEmailErr("Please enter a valid email address.");
      return;
    } else {
      setEmailErr("");
    }

    if (!name || name.length < 3) {
      toast.error("Name should have atleast 3 characters");
      setNameErr("Name should have atleast 3 characters");
      return;
    } else {
      setNameErr("");
    }

    if (!mblNo || mblNo.length !== 10) {
      toast.error("Invalid Mobile Number");
      setMblNoErr(
        "Invalid Mobile Number",
        "Please enter a valid 10-digit mobile number."
      );
      return;
    } else {
      setMblNoErr("");
    }

    if (
      !Password ||
      Password.length < 6 ||
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9.!@#$%^&*]+$/.test(Password)
    ) {
      toast.error(
        "Password - least 6 characters - at least one digit - one special character."
      );
      setPasswordErr(
        "Password - least 6 characters - at least one digit - one special character."
      );
      return;
    } else {
      setPasswordErr("");
    }

    if (Password !== confirmPassword) {
      toast.error("Password and Confirm Password do not match.");
      setConfirmPasswordErr("Password and Confirm Password do not match.");
      return;
    } else {
      setConfirmPasswordErr("");
    }

    setBtnLoader(true);

    CallPostApiServices(
      `/user/new-registration-otp`,
      {
        email: email,
      },
      (response) => {
        if (response.status === 200) {
          setBtnLoader(false);
          setOtpFromBackend(response.data.otp);
          setErrFromBackend(null);
          setShowModal(true);
          toast.success("OTP sent to your Email");
        }
      },
      (err) => {
        console.log("err sending otp on registration");
        setBtnLoader(false);
        if (err.response?.data.message) {
          toast.error(err.response.data.message);
          setErrFromBackend(err.response.data.message);
        } else if (err.message) {
          toast.error(err.message);
          setErrFromBackend(err.message);
        } else {
          toast.error("Server error");
        }
      }
    );
  }

  function handleOtpSubmit() {
    if (otpFromBackend !== userEnteredOtp) {
      toast.error("Invalid OTP", { autoClose: 1500 });
      return;
    } else {
      setOtpBtnLoader(true);
      toast.success("OTP verified", { autoClose: 1500 });
      setTimeout(() => {
        setBtnLoader(true);
        setOtpBtnLoader(false);
        closeModal();
        setUserEnteredOtp(null);
        registerAfterOTPHandler();
      }, 1500);
    }
  }

  function closeModal() {
    setShowModal(false);
  }

  function registerAfterOTPHandler() {
    setBtnLoader(true);
    CallPostApiServices(
      `/user/create-user`,
      {
        name: name,
        email: email,
        mobileNumber: mblNo,
        password: Password,
        courseType: authCtx.userSelectedCourseFromPricingPage || "none",
        triedToUpdate: false,
      },
      (response) => {
        if (response.status === 201) {
          setErrFromBackend(null);
          toast.success("Registered successfully, Redirecting...", {
            autoClose: 2000,
          });
          setTimeout(() => {
            setBtnLoader(false);
            handlePayment();
          }, 2000);
        }
      },
      (err) => {
        console.log("err on signup", err);
        setBtnLoader(false);
        if (err.response?.data.message) {
          toast.error(err.response.data.message);
          setErrFromBackend(err.response.data.message);
        } else if (err.message) {
          toast.error(err.message);
          setErrFromBackend(err.message);
        } else {
          toast.error("Server error");
        }
      }
    );
  }

  const handlePayment = async () => {
    setBtnLoader(true);
    if (isValidToken) {
      event(
        "FunctionCall",
        `phonepe payment function called by registered user ${userEmail}`,
        "none"
      );
    } else {
      event("FunctionCall", "phonepe payment function called", ipAddress);
    }
    CallPostApiServices(
      `/order/payment`,
      {
        ...data,
      },
      (res) => {
        setBtnLoader(false);
        // setUpdatingPlan(false);
        if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {
          window.location.href =
            res.data.data.instrumentResponse.redirectInfo.url;
        }
      },
      (err) => {
        console.log("Payment error");
        toast.error("Server error, Please try again");
        setBtnLoader(false);
      }
    );
  };

  // console.log("up", updatingPlan);

  return (
    <div className={styles.course_detail_container_main}>
      <Helmet>
        <title>CheckOut - charteey</title>
        <link rel="canonical" href="https://charteey.com/course-details" />
        <meta
          name="description"
          content="Unlock the secrets of successful trading with our comprehensive course. Dive deep into strategies, techniques, and analysis methods to elevate your trading skills. Explore now!"
        />
      </Helmet>
      <div className={styles.course_detail_container_main_sub}>
        <div className={styles.course_detail_container_main_sub_reg_det}>
          <div
            className={styles.about_course_cont}
            style={{ marginTop: "0rem" }}
          >
            <span className={styles.about_course_cont_header}>Plan</span>
            <div
              className={styles.about_course_cont_content}
              style={{
                height:
                  userSelectedCourseFromPricingPage === "analysis"
                    ? "fit-content"
                    : "fit-content",
              }}
            >
              <span className={styles.course_detail_header}>
                {userSelectedCourseFromPricingPage === "analysis"
                  ? "STRATEGIC INSIGHTS - HYBRID ANALYSIS PLAN"
                  : userSelectedCourseFromPricingPage === "standard"
                  ? "MASTER DATA ANALYTICS : TECHNIQUES AND STRATEGIES"
                  : userSelectedCourseFromPricingPage === "pro"
                  ? "SYNERGIZING PRICE ACTION WITH FUNDAMENTALS FOR MARKET MASTERY"
                  : ""}
              </span>
            </div>
          </div>

          <div className={styles.about_course_cont}>
            <span className={styles.about_course_cont_header}>
              Registration Details
            </span>

            <div className={styles.login_container}>
              <form className={styles.form}>
                <div className={styles.form_group}>
                  <label className={styles.label} htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className={styles.input_field}
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={email}
                    disabled={(isValidToken && !paid) || (isValidToken && paid)}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={styles.form_column}>
                  <div className={styles.form_group}>
                    <label className={styles.label} htmlFor="name">
                      Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className={styles.input_field}
                      type="text"
                      id="name"
                      name="name"
                      required
                      value={name}
                      disabled={
                        (isValidToken && !paid) || (isValidToken && paid)
                      }
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className={styles.form_group}>
                    <label className={styles.label} htmlFor="mobile">
                      Mobile No <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className={styles.input_field}
                      type="tel"
                      id="mobile"
                      name="mobile"
                      required
                      value={mblNo}
                      disabled={
                        (isValidToken && !paid) || (isValidToken && paid)
                      }
                      onChange={(e) => setMblNo(e.target.value)}
                    />
                  </div>
                </div>

                {!isValidToken && !paid && (
                  <div className={styles.form_column}>
                    <div className={styles.form_group}>
                      <label className={styles.label} htmlFor="password">
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className={styles.input_field}
                        type="password"
                        id="password"
                        name="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className={styles.form_group}>
                      <label className={styles.label} htmlFor="confirmPassword">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className={styles.input_field}
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {/* <ButtonComponent
                  text={"Register"}
                  style={styles.submit_button}
                  indicator={btnLoader}
                  handler={signUpClickHandler}
                /> */}
              </form>
            </div>
          </div>

          <div
            className={styles.about_course_cont}
            style={{ marginTop: "1.4rem" }}
          >
            <span className={styles.about_course_cont_header}>Payment</span>
            {windowWidth > 500 && (
              <div className={styles.you_pay_cont_sub}>
                <input
                  type="radio"
                  id="radio-btn"
                  name="checkout"
                  value={true}
                  defaultChecked={true}
                />
                <span>PhonePe Payment Gateway (UPI, Cards & NetBanking)</span>
                <div className={styles.you_pay_cont_sub_price_cont}>
                  <img src={Upi} alt="upi-icon" />
                  <img src={Visa} alt="visa-icon" />
                  <img src={Mastercard} alt="mastercard-icon" />
                  <img src={Rupay} alt="rupay-icon" />
                </div>
              </div>
            )}
            {windowWidth <= 500 && (
              <div className={styles.you_pay_cont_sub}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    color: "#c9c8c7",
                  }}
                >
                  <input
                    type="radio"
                    id="radio-btn"
                    name="checkout"
                    value={true}
                    checked={true}
                  />
                  <span>PhonePe Payment Gateway (UPI, Cards & NetBanking)</span>
                </div>
                <div className={styles.you_pay_cont_sub_price_cont}>
                  <img src={Upi} alt="upi-icon" />
                  <img src={Visa} alt="visa-icon" />
                  <img src={Mastercard} alt="mastercard-icon" />
                  <img src={Rupay} alt="rupay-icon" />
                </div>
              </div>
            )}
            <div className={styles.redirect_cont}>
              <img src={Redirect} alt="redirect-icon" />
              <span>
                After clicking “Pay now”, you will be redirected to PhonePe
                Payment Gateway (UPI, Cards & NetBanking) to complete your
                purchase securely.
              </span>
            </div>

            {windowWidth > 870 && (
              <div
                className={styles.pay_now_btn_cont}
                style={{ marginTop: "1.3rem" }}
              >
                <ButtonComponent
                  text={"Pay now"}
                  style={styles.buy_now_btn}
                  indicator={btnLoader}
                  handler={signUpClickHandler}
                  disabled={btnLoader}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.course_detail_container_main_sub_details}>
          <div className={styles.course_detail_container_main_sub_details_sub}>
            <div className={styles.summary_cont}>
              <span className={styles.about_course_cont_header}>Summary</span>
              <div className={styles.buy_now_cont}>
                <div className={styles.price_cont_text}>
                  <span>
                    {userSelectedCourseFromPricingPage === "analysis"
                      ? "Strategic Insights - Hybrid Analysis Plan"
                      : userSelectedCourseFromPricingPage === "standard"
                      ? "MASTER DATA ANALYTICS : TECHNIQUES AND STRATEGIES"
                      : userSelectedCourseFromPricingPage === "pro"
                      ? "Synergizing Price Action With Fundamentals For Market Mastery"
                      : ""}
                  </span>
                </div>
                {/* <ButtonComponent
                style={styles.buy_now_btn}
                text={"Buy now"}
                handler={buyNowHandler}
                indicator={buyNowLoader}
              /> */}
                {/* <div className={styles.price_cont}>
                  <span>
                    {userSelectedCourseFromPricingPage === "analysis" &&
                      "₹ 399"}
                    {userSelectedCourseFromPricingPage === "standard" &&
                      "₹ 4,499"}
                    {userSelectedCourseFromPricingPage === "pro" && "₹ 2,999"}
                  </span>
                </div> */}
              </div>
              <hr
                style={{
                  backgroundColor: "#0c969a",
                  height: "2px",
                  border: "none",
                  margin: 0,
                  marginTop: "0.3rem",
                }}
              />
            </div>
            <div className={styles.summary_cont}>
              <div className={styles.buy_now_cont}>
                <div className={styles.price_cont_text}>
                  <span style={{ fontSize: "14px" }}>Subtotal</span>
                </div>

                <div className={styles.price_cont}>
                  <span style={{ fontSize: "15px", color: "#9ba1a6" }}>
                    {userSelectedCourseFromPricingPage === "analysis" && "₹299"}
                    {userSelectedCourseFromPricingPage === "standard" &&
                      "₹4,499"}
                    {userSelectedCourseFromPricingPage === "pro" && "₹2,999"}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.summary_cont}>
              <div className={styles.buy_now_cont}>
                <div className={styles.price_cont_text}>
                  <span style={{ fontSize: "15px" }}>TOTAL TO PAY</span>
                </div>

                <div className={styles.price_cont}>
                  <span style={{ fontSize: "16px" }}>
                    {userSelectedCourseFromPricingPage === "analysis" && "₹299"}
                    {userSelectedCourseFromPricingPage === "standard" &&
                      "₹4,499"}
                    {userSelectedCourseFromPricingPage === "pro" && "₹ 2,999"}
                  </span>
                </div>
              </div>

              <span style={{ fontSize: "13px", color: "#9ba1a6" }}>
                Including{" "}
                {userSelectedCourseFromPricingPage === "analysis"
                  ? "₹53.82"
                  : "539.82"}{" "}
                in taxes (GST)
              </span>
            </div>

            <div className={styles.summary_cont}>
              <ButtonComponent
                text={"Pay now"}
                style={styles.buy_now_btn}
                indicator={btnLoader}
                handler={signUpClickHandler}
                disabled={btnLoader}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        // onRequestClose={closeModal}
        contentLabel="OTP Modal"
        style={{
          overlay: {
            position: "fixed",
            zIndex: 1020,
            margin: "auto",
            width: "100vw",
            height: "100vh",
            background: "rgba(30, 30, 30, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
          },
          content: {
            background: "rgba(30, 30, 30, 1)",
            minWidth: windowWidth <= 460 ? "90%" : "25rem",
            height: "13rem",
            overflowY: "auto",
            border: "1px solid #3f4345",
            borderRadius: "0.3rem",
            position: "unset",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <div className={styles.modal_div}>
          <input
            className={styles.input_field}
            placeholder="Enter OTP"
            style={{ backgroundColor: "#3f4345", textAlign: "center" }}
            type="text"
            value={userEnteredOtp}
            onChange={(e) => setUserEnteredOtp(e.target.value)}
          />
          <ButtonComponent
            handler={handleOtpSubmit}
            text={"Verify"}
            indicator={otpBtnLoader}
          />
        </div>
      </Modal>
    </div>
  );
}

export default CheckoutPage;
