import { useState, useEffect } from "react";
import TickIcon from "../../../../../../assets/icons/tick.svg";
import CrossIcon from "../../../../../../assets/icons/crossIcon.svg";
import FlowImg from "../../../../../../assets/images/flowImg1.svg";
import FlowImg2 from "../../../../../../assets/images/flowImg2.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./secretSection.module.css";
import AnalysisCarousel from "../../components/analysisCarousel";

function SecretSection(props) {
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.secret_cont}>
      <div className={styles.secret_cont_sub}>
        <div className={styles.secret_heading_cont}>
          <h2>
            The Analytics Skills Are the Key to Your Consistent Trading Success
          </h2>
          <p>
            Among India's vast population of 87,000,000 investors and traders,
            only <b>.00000003% achieve consistent profitability!</b>
          </p>
        </div>
        <div className={styles.secret_img_cont}>
          {windowWidth > 730 ? (
            <img src={FlowImg} alt="secret-chart-img" />
          ) : (
            <img src={FlowImg2} alt="secret-chart-img" />
          )}
          <div>
            <span>What we do in this gap decides the portfolio's future</span>
            <span>
              This is where the
              <b style={{ color: "#0067de", fontWeight: "500" }}>
                {" "}
                .00000003% of traders dominate with deep hybrid analysis
              </b>
            </span>
          </div>
        </div>
        <div className={styles.secret_diff_cont}>
          <div className={styles.secret_diff_sub}>
            <h2>Without Hybrid Analysis</h2>
            <ul>
              <li>
                <img src={CrossIcon} alt="tick-icon" />
                <span>High Risk</span>
              </li>
              <li>
                <img src={CrossIcon} alt="tick-icon" />
                <span>Inconsistent Profits</span>
              </li>
              <li>
                <img src={CrossIcon} alt="tick-icon" />
                <span>Guesswork Decisions</span>
              </li>
              <li>
                <img src={CrossIcon} alt="tick-icon" />
                <span>Frustration</span>
              </li>
            </ul>
          </div>
          <div className={styles.secret_diff_sub}>
            <h2>With Hybrid Analysis</h2>

            <ul>
              <li>
                <img src={TickIcon} alt="tick-icon" />
                <span>Reduced Risk</span>
              </li>
              <li>
                <img src={TickIcon} alt="tick-icon" />
                <span>Consistent Profits</span>
              </li>
              <li>
                <img src={TickIcon} alt="tick-icon" />
                <span>Data-Driven Decisions</span>
              </li>
              <li>
                <img src={TickIcon} alt="tick-icon" />
                <span>Confidence</span>
              </li>
            </ul>
          </div>
        </div>

        <section className={styles.carousel_cont}>
          <h2>See How Hybrid Analysis Dominates the Market</h2>
          <AnalysisCarousel />
        </section>

        <div className={styles.one_btn_cont}>
          <button
            onClick={() =>
              props.cta("FUNNEL - Carousel container CTA btn clicked")
            }
          >
            Get it for ₹299{" "}
            <div
              onClick={() =>
                props.cta("FUNNEL - Carousel container CTA btn clicked")
              }
            >
              <MdKeyboardArrowRight color="#000" size={25} />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default SecretSection;
